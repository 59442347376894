import Dashboard from 'views/Dashboard/Dashboard';
import Transfer from 'views/Transfer/Transfer';
import TransferDetail from 'views/Transfer/TransferDetail';
import TransfersInProgress from 'views/Transfer/TransfersInProgress';
import Notifications from 'views/Notifications/Notifications';
import Reports from 'views/Reports/Reports';
import Telecare from 'views/Telecare/Telecare';
// import ListResources from 'views/Resources/ResourcesListResume';
// import Agreements from 'views/Agreements/Agreements';
// import Charts from 'views/Charts/Charts';

// import OcupationForm from 'nxComponents/Resources/Ocupation';
// import ResourceDetails from 'nxComponents/Resources/ResourceDetails';
// import Capacity from 'nxComponents/Resources/Capacity';
// import BloodBankForm from 'nxComponents/Resources/BloodBankForm';

// ToDo -> Mover esas rutas que se llama de nxComponents a view, ya que son containers que manejan estado
//  @material-ui/icons
import DashboardIcon from '@material-ui/icons/Dashboard';
import AssignmentReturned from '@material-ui/icons/AssignmentReturned';
import TrasnferIcon from '@material-ui/icons/SwapHorizontalCircle';
import VideoCallIcon from '@material-ui/icons//VideoCall';
import DownloadFile from '../nxComponents/DownloadFile/DownloadFile';
// import AssignmentIcon from '@material-ui/icons/Assignment';
// import AgreementsIcon from '@material-ui/icons/VerifiedUser';
// import ChartIcon from '@material-ui/icons/PieChart';
// import PeopleIcon from '@material-ui/icons/People';
// import AttentionNetwork from '../nxComponents/AttentionNetworks/AttentionNetworks';
// create_referrals
// read_referrals
// cancel_referrals
// assign_amb
// assign_ips
// access_BDUA
// report_hosp_resources
// read_hosp_resources
// report_blood_resources
// read_blood_resources
// view_map
// read_all_resources

// IPS_REFERRAL_REPORTER
// False	Rol para usuarios de las IPS que pueden gestionar traslados	Edit	Delete

// CRUE_REFERRAL_MANAGER
// False	Rol para usuarios del CRUE que gestionan los traslados en el Módulo 123	Edit	Delete

// SEM_ADMIN_SUPPORT
// False	Rol para usuarios del Netux que prestan soporte a la aplicación SEM	Edit	Delete

// IPS_BLOOD_RESOURCES
// False	Rol para usuarios de las IPS que pueden gestionar recursos del banco de sangre	Edit	Delete

// EPS_REFERRAL_MANAGER
// False	Rol para usuarios del EPS que gestionan los traslados de sus pacientes	Edit	Delete

// IPS_HOSPITALARY_RESOURCES
// False	Rol para usuarios de las IPS que pueden gestionar recursos hospitalarios	Edit	Delete

// CRUE_RESOURCES_AUDITOR
// Rol para usuarios del CRUE que revisan los recursos reportados por las IPS

// TRANSPORT_REFERRAL_MANAGER
// Rol para usuarios de ambulancias privadas(no asistenciales) que gestionan el depacho de las ambulancias privadas

const dashRoutes = [
  {
    path: '/dashboard',
    name: 'Tablero de mando',
    icon: DashboardIcon,
    component: Dashboard,
    hidden: true
  },
  {
    path: '/notifications',
    hidden: true,
    component: Notifications
  },
  {
    path: '/downloadFile',
    hidden: true,
    component: DownloadFile,
    roles: ['CRUE_REFERRAL_MANAGER', 'IPS_REFERRAL_REPORTER', 'EPS_REFERRAL_MANAGER']
  },
  {
    path: '/downloadChatFile',
    hidden: true,
    component: DownloadFile,
    roles: ['CRUE_REFERRAL_MANAGER', 'IPS_REFERRAL_REPORTER', 'EPS_REFERRAL_MANAGER']
  },
  {
    collapse: true,
    path: '/transfer',
    name: 'Traslados',
    state: 'openTraslados',
    icon: TrasnferIcon,
    roles: [
      'CRUE_REFERRAL_MANAGER',
      'IPS_REFERRAL_REPORTER',
      'EPS_REFERRAL_MANAGER',
      'TRANSPORT_REFERRAL_MANAGER',
      'CRUE_DEP',
      'CRUE_DEP_REFERRAL_MANAGER'
    ],
    views: [
      {
        path: '/traslados/solicitud',
        name: 'Solicitud de traslado',
        mini: 'ST',
        component: Transfer,
        roles: ['IPS_REFERRAL_REPORTER']
      },
      {
        path: '/traslados/en_progreso',
        name: 'Traslados en curso',
        mini: 'EC',
        component: TransfersInProgress,
        roles: [
          'CRUE_REFERRAL_MANAGER',
          'IPS_REFERRAL_REPORTER',
          'EPS_REFERRAL_MANAGER',
          'TRANSPORT_REFERRAL_MANAGER',
          'CRUE_DEP',
          'CRUE_DEP_REFERRAL_MANAGER'
        ]
      },
      {
        path: '/traslados/:transferId',
        name: 'Detalles del traslado',
        hidden: true,
        icon: DashboardIcon,
        component: TransferDetail,
        roles: [
          'CRUE_REFERRAL_MANAGER',
          'IPS_REFERRAL_REPORTER',
          'EPS_REFERRAL_MANAGER',
          'TRANSPORT_REFERRAL_MANAGER',
          'CRUE_DEP',
          'CRUE_DEP_REFERRAL_MANAGER'
        ]
      }
    ]
  },

  // {
  //   collapse: true,
  //   path: '/resources',
  //   name: 'Recursos',
  //   // state: 'openTraslados',
  //   icon: AssignmentIcon,
  //   roles: ['IPS_BLOOD_RESOURCES', 'IPS_HOSPITALARY_RESOURCES', 'CRUE_RESOURCES_AUDITOR', 'CRUE_REFERRAL_MANAGER'],
  //   views: [
  //     {
  //       path: '/recursos/:ipsId',
  //       name: 'Detalles del recurso',
  //       mini: 'DR',
  //       hidden: true,
  //       component: ResourceDetails,
  //       roles: ['CRUE_REFERRAL_MANAGER', 'CRUE_RESOURCES_AUDITOR', 'IPS_HOSPITALARY_RESOURCES']
  //     },
  //     {
  //       path: '/recursos',
  //       name: 'Listado de Recursos',
  //       mini: 'LR',
  //       component: ListResources,
  //       roles: ['CRUE_REFERRAL_MANAGER', 'CRUE_RESOURCES_AUDITOR']
  //     },
  //     {
  //       path: '/resources/capacity',
  //       name: 'Capacidad',
  //       mini: 'C',
  //       component: Capacity,
  //       roles: ['IPS_HOSPITALARY_RESOURCES']
  //     },
  //     {
  //       path: '/resources/ocupation',
  //       name: 'Ocupación y disponibilidad',
  //       mini: 'O',
  //       component: OcupationForm,
  //       roles: ['IPS_HOSPITALARY_RESOURCES']
  //     },
  //     {
  //       path: '/resources/blood-bank',
  //       name: 'Banco de sangre',
  //       mini: 'BS',
  //       component: BloodBankForm,
  //       roles: ['IPS_BLOOD_RESOURCES']
  //     }
  //   ]
  // },
  {
    path: '/reports',
    name: 'Reportes',
    icon: AssignmentReturned,
    component: Reports,
    roles: [
      'CRUE_REFERRAL_MANAGER',
      'EPS_REFERRAL_MANAGER',
      'IPS_REFERRAL_MANAGER',
      'IPS_REFERRAL_AUDITOR',
      'EPS_REFERRAL_AUDITOR',
      'TRANSPORT_REFERRAL_MANAGER',
      'TRANSPORT_REFERRAL_AUDITOR'
    ]
  },
  // {
  //   path: '/agreements',
  //   name: 'Acuerdos',
  //   icon: AgreementsIcon,
  //   component: Agreements,
  //   roles: ['IPS_REFERRAL_REPORTER', 'EPS_REFERRAL_MANAGER', 'TRANSPORT_REFERRAL_MANAGER']
  // },
  // {
  //   path: '/networks',
  //   name: 'Redes de atención',
  //   icon: PeopleIcon,
  //   component: AttentionNetwork,
  //   roles: ['CRUE_REFERRAL_MANAGER']
  // },
  {
    path: '/telecare',
    name: 'Teleorientación',
    icon: VideoCallIcon,
    component: Telecare,
    roles: ['CRUE_REFERRAL_MANAGER']
  },
  // {
  //   path: '/charts',
  //   name: 'Indicadores',
  //   icon: ChartIcon,
  //   component: Charts,
  //   roles: ['CRUE_REFERRAL_MANAGER']
  // },
  {
    redirect: true,
    path: '/',
    pathTo: '/traslados/en_progreso',
    name: 'Traslados'
  }
];
export default dashRoutes;
