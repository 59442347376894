import React from 'react';
import { Toolbar } from '@devexpress/dx-react-grid-material-ui';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    flexFlow: 'row wrap',
    '& div:empty': {
      display: 'none'
    },
    gap: '10px',
    '@media (max-width: 700px)': {
      padding: '10px 20px',
      alignItems: 'start',
      flexDirection: 'column'
    }
  }
});

const ToolbarRoot = props => {
  const classes = useStyles();
  return <Toolbar.Root {...props} className={classes.root} />;
};

export default ToolbarRoot;
