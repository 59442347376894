export const keysToValidateBlood = [
  'redbloodCellsOp',
  'redbloodCellsOn',
  'redbloodCellsAp',
  'redbloodCellsAn',
  'redbloodCellsBp',
  'redbloodCellsBn',
  'redbloodCellsABp',
  'redbloodCellsABn',
  'plasmaOp',
  'plasmaOn',
  'plasmaAp',
  'plasmaAn',
  'plasmaBp',
  'plasmaBn',
  'plasmaABp',
  'plasmaABn'
];

export const resources = {
  adultBeds: '',
  pediatricBeds: '',
  obstetricsBeds: '',
  litter: '',
  uceBeds: '',
  uciBeds: '',
  tomograph: true,
  resonator: true,
  angiograph: true,
  xrays: false,
  redbloodCellsOp: '',
  redbloodCellsOn: '',
  'redbloodCellsA+': '',
  'redbloodCellsA-': '',
  'redbloodCellsB+': '',
  'redbloodCellsB-': '',
  'redbloodCellsAB+': '',
  'redbloodCellsAB-': '',
  'plasmaO+': '',
  'plasmaO-': '',
  'plasmaA+': '',
  'plasmaA-': '',
  'plasmaB+': '',
  'plasmaB-': '',
  'plasmaAB+': '',
  'plasmaAB-': '',
  observations: '',
  diagnosticAidsReasons: {
    tomograph: '',
    resonator: '',
    angiograph: '',
    xrays: ''
  }
};

export const redbloodCell = [
  { name: 'redbloodCellsOp', label: 'O+' },
  { name: 'redbloodCellsOn', label: 'O-' },
  { name: 'redbloodCellsAp', label: 'A+' },
  { name: 'redbloodCellsAn', label: 'A-' },
  { name: 'redbloodCellsBp', label: 'B+' },
  { name: 'redbloodCellsBn', label: 'B-' },
  { name: 'redbloodCellsABp', label: 'AB+' },
  { name: 'redbloodCellsABn', label: 'AB-' }
];

export const plasma = [
  { name: 'plasmaOp', label: 'O+' },
  { name: 'plasmaOn', label: 'O-' },
  { name: 'plasmaAp', label: 'A+' },
  { name: 'plasmaAn', label: 'A-' },
  { name: 'plasmaBp', label: 'B+' },
  { name: 'plasmaBn', label: 'B-' },
  { name: 'plasmaABp', label: 'AB+' },
  { name: 'plasmaABn', label: 'AB-' }
];

export const noAvailableReasons = [
  { id: 2, name: 'Terminación de contrato', value: '2' },
  {
    id: 3,
    name: 'Incapacidad o novedad temporal del recurso humano',
    value: '3'
  },
  { id: 4, name: 'Cierre del servicio', value: '4' },
  { id: 5, name: 'El servicio desborda la capacidad de atención', value: '5' },
  { id: 1, name: 'Otro', value: '1' }
];

export const diagnosticAidsReason = [
  { id: 2, name: 'Daño', value: '2' },
  { id: 3, name: 'Mantenimiento', value: '3' },
  { id: 4, name: 'Falta de materiales, medicamentos o insumos', value: '4' },
  { id: 5, name: 'Falta de recurso humano', value: '5' },
  { id: 1, name: 'Otro', value: '1' }
];

export const humanResourcesFields = [
  {
    id: 1,
    label: 'Cirugía general',
    name: 'generalSurgery',
    scheduleName: 'generalSurgerySchedule',
    placeholder: '¿Por qué no cuenta con el servicio de cirugía general?',
    reasonName: 'generalSurgeryReason'
  },
  {
    id: 2,
    label: 'Ortopedia',
    name: 'orthopaedic',
    scheduleName: 'orthopaedicSchedule',
    placeholder: '¿Por qué no cuenta con el servicio de ortopedia?',
    reasonName: 'orthopaedicReason'
  },
  {
    id: 3,
    label: 'Neurocirugía',
    name: 'neurosurgery',
    scheduleName: 'neurosurgerySchedule',
    placeholder: '¿Por qué no cuenta con el servicio de neurocirugía?',
    reasonName: 'neurosurgeryReason'
  },
  {
    id: 4,
    label: 'Radiología intervencionista',
    name: 'radiology',
    scheduleName: 'radiologySchedule',
    placeholder: '¿Por qué no cuenta con el servicio de radiología intervencionista?',
    reasonName: 'radiologyReason'
  },
  {
    id: 5,
    label: 'Cirugía plástica',
    name: 'plasticSurgery',
    scheduleName: 'plasticSurgerySchedule',
    placeholder: '¿Por qué no cuenta con el servicio de cirugía plástica?',
    reasonName: 'plasticSurgeryReason'
  },
  {
    id: 6,
    label: 'Cirugía oral y maxilofacial',
    name: 'oralSurgery',
    scheduleName: 'oralSurgerySchedule',
    placeholder: '¿Por qué no cuenta con el servicio de Cirugía oral y maxilofacial?',
    reasonName: 'oralSurgeryReason'
  },
  {
    id: 7,
    label: 'Cirugía cardiovascular',
    name: 'cardiovascularSurgery',
    scheduleName: 'cardiovascularSurgerySchedule',
    placeholder: '¿Por qué no cuenta con el servicio de cirugía cardiovascular?',
    reasonName: 'cardiovascularSurgeryReason'
  },
  {
    id: 8,
    label: 'Cirugía vascular periférica',
    name: 'peripheralVascularSurgery',
    scheduleName: 'peripheralVascularSurgerySchedule',
    placeholder: '¿Por qué no cuenta con el servicio de cirugía vascular?',
    reasonName: 'peripheralVascularSurgeryReason'
  },
  {
    id: 9,
    label: 'Cirugía microvascular',
    name: 'microvascularSurgery',
    scheduleName: 'microvascularSurgerySchedule',
    placeholder: '¿Por qué no cuenta con el servicio de cirugía microvascular?',
    reasonName: 'microvascularSurgeryReason'
  },
  {
    id: 10,
    label: 'Cirugía de cabeza y cuello',
    name: 'headSurgery',
    scheduleName: 'headSurgerySchedule',
    placeholder: '¿Por qué no cuenta con el servicio de cirugía de cabeza?',
    reasonName: 'headSurgeryReason'
  },
  {
    id: 11,
    label: 'Cirugía oftálmica',
    name: 'ocularSurgery',
    scheduleName: 'ocularSurgerySchedule',
    placeholder: '¿Por qué no cuenta con el servicio de cirugía oftálmica?',
    reasonName: 'ocularSurgeryReason'
  },
  {
    id: 12,
    label: 'Otorrinolaringología',
    name: 'otorhinolaryngology',
    scheduleName: 'otorhinolaryngologySchedule',
    placeholder: '¿Por qué no cuenta con el servicio de otorrinolaringología?',
    reasonName: 'otorhinolaryngologyReason'
  },
  {
    id: 13,
    label: 'Cirugía de tórax',
    name: 'thoracicSurgery',
    scheduleName: 'thoracicSurgerySchedule',
    placeholder: '¿Por qué no cuenta con el servicio de cirugía de tórax?',
    reasonName: 'thoracicSurgeryReason'
  },
  {
    id: 14,
    label: 'Cirugía pediátrica',
    name: 'pediatricSurgery',
    scheduleName: 'pediatricSurgerySchedule',
    placeholder: '¿Por qué no cuenta con el servicio de cirugía pediátrica?',
    reasonName: 'pediatricSurgeryReason'
  },
  {
    id: 15,
    label: 'Urología',
    name: 'urology',
    scheduleName: 'urologySchedule',
    placeholder: '¿Por qué no cuenta con el servicio de urología?',
    reasonName: 'urologyReason'
  },
  {
    id: 16,
    label: 'Cirugía gineco-obstétrica',
    name: 'obstetricSurgery',
    scheduleName: 'obstetricSurgerySchedule',
    placeholder: '¿Por qué no cuenta con el servicio cirugía gineco-obstétrica?',
    reasonName: 'obstetricSurgeryReason'
  }
];

export const humanResourcesScheduleFields = [
  'generalSurgerySchedule',
  'orthopaedicSchedule',
  'neurosurgerySchedule',
  'radiologySchedule',
  'plasticSurgerySchedule',
  'oralSurgerySchedule',
  'cardiovascularSurgerySchedule',
  'peripheralVascularSurgerySchedule',
  'microvascularSurgerySchedule',
  'headSurgerySchedule',
  'ocularSurgerySchedule',
  'otorhinolaryngologySchedule',
  'thoracicSurgerySchedule',
  'pediatricSurgerySchedule',
  'urologySchedule',
  'obstetricSurgerySchedule'
];

export const nonSurgicalSupportFields = [
  {
    id: 1,
    label: 'Medicina interna',
    name: 'internalMedicine',
    scheduleName: 'internalMedicineSchedule',
    placeholder: '¿Por qué no tiene disponible el servicio de medicina interna?',
    reasonName: 'internalMedicineReason'
  },
  {
    id: 2,
    label: 'Pediatría',
    name: 'pediatric',
    scheduleName: 'pediatricSchedule',
    placeholder: '¿Por qué no tiene disponible el servicio de pediatría?',
    reasonName: 'pediatricReason'
  },
  {
    id: 3,
    label: 'Nefrología',
    name: 'nephrology',
    scheduleName: 'nephrologySchedule',
    placeholder: '¿Por qué no tiene disponible el servicio de nefrología?',
    reasonName: 'nephrologyReason'
  },
  {
    id: 4,
    label: 'Psiquiatría',
    name: 'psychiatry',
    scheduleName: 'psychiatrySchedule',
    placeholder: '¿Por qué no tiene disponible el servicio de psiquiatría?',
    reasonName: 'psychiatryReason'
  },
  {
    id: 5,
    label: 'Neuroradiología',
    name: 'neuroRadiology',
    scheduleName: 'neuroRadiologySchedule',
    placeholder: '¿Por qué no tiene disponible el servicio de neuroradiología?',
    reasonName: 'neuroRadiologyReason'
  },
  {
    id: 6,
    label: 'Hemodinamia',
    name: 'hemodynamics',
    scheduleName: 'hemodynamicsSchedule',
    placeholder: '¿Por qué no tiene disponible el servicio de hemodinamia?',
    reasonName: 'hemodynamicsReason'
  },
  {
    id: 7,
    label: 'Especialista en urgencias',
    name: 'emergencySpecialist',
    scheduleName: 'emergencySpecialistSchedule',
    placeholder: '¿Por qué no tiene disponible el servicio de Especialista en urgencias?',
    reasonName: 'emergencySpecialistReason'
  }
];

export const supportServiceFields = [
  {
    id: 1,
    checkLabel: 'Servicio de diálisis',
    checkName: 'dialysisService',
    qtyName: 'dialysisServiceQty',
    qtyLabel: 'Cantidad de servicios de diálisis',
    placeholder: '¿Por qué no tiene disponible el servicio de diálisis?',
    reasonName: 'dialysisServiceReason'
  },
  {
    id: 2,
    checkLabel: 'Laboratorio clínico',
    checkName: 'clinicalLab',
    qtyName: 'clinicalLabQty',
    qtyLabel: 'Cantidad de Laboratorios',
    placeholder: '¿Por qué no tiene disponible el laboratorio clínico?',
    reasonName: 'clinicalLabReason'
  },
  {
    id: 3,
    checkLabel: 'Quirófano',
    checkName: 'operatingRoom',
    qtyName: 'operatingRoomQty',
    qtyLabel: 'Cantidad de quirófanos',
    placeholder: '¿Por qué no tiene disponible el quirófano?',
    reasonName: 'operatingRoomReason'
  },
  {
    id: 4,
    checkLabel: 'Banco de sangre',
    checkName: 'bloodBank',
    qtyName: 'bloodBankQty',
    qtyLabel: 'Cantidad de bancos',
    placeholder: '¿Por qué no tiene disponible el banco de sangre?',
    reasonName: 'bloodBankReason'
  }
];
export const diagnosticAids = [
  {
    id: 1,
    checkLabel: 'Tomógrafo',
    checkName: 'tomograph',
    reasonLabel: '¿Por qué no cuenta con Tomógrafo?',
    qtyName: 'tomographQty',
    qtyLabel: 'Cantidad de tomógrafos',
    placeholder: '¿Por qué no tiene disponible el tomógrafo?',
    reasonName: 'tomographReason'
  },
  {
    id: 2,
    checkLabel: 'Resonador',
    checkName: 'resonator',
    reasonLabel: '¿Por qué no cuenta con Resonador?',
    qtyName: 'resonatorQty',
    qtyLabel: 'Cantidad de resonadores',
    placeholder: '¿Por qué no tiene disponible el resonador?',
    reasonName: 'resonatorReason'
  },
  {
    id: 3,
    checkLabel: 'Angiógrafo',
    checkName: 'angiograph',
    reasonLabel: '¿Por qué no cuenta con Angiógrafo?',
    qtyName: 'angiographQty',
    qtyLabel: 'Cantidad de angiógrafos',
    placeholder: '¿Por qué no tiene disponible el angiógrafo?',
    reasonName: 'angiographReason'
  },
  {
    id: 4,
    checkLabel: 'Rayos X',
    checkName: 'xrays',
    reasonLabel: '¿Por qué no cuenta con rayos X?',
    qtyName: 'xraysQty',
    qtyLabel: 'Cantidad de rayos X',
    placeholder: '¿Por qué no tiene disponible los rayos X?',
    reasonName: 'xraysReason'
  },
  {
    id: 5,
    checkLabel: 'Ultrasonógrafo',
    checkName: 'ultrasonograph',
    reasonLabel: '¿Por qué no cuenta con ultrasonógrafo?',
    qtyName: 'ultrasonographQty',
    qtyLabel: 'Cantidad de ultrasonógrafos',
    placeholder: '¿Por qué no tiene disponible el ultrasonógrafo?',
    reasonName: 'ultrasonographReason'
  }
];

export const internmentFields = [
  {
    id: 1,
    checkName: 'neonatalUciHosp',
    checkLabel: '¿Tiene habilitado UCI neonatal ?',
    qtyName: 'neonatalUciBedsHosp',
    qtyLabel: 'Camas neonatal UCI',
    qtyLabelOccupation: 'Pacientes neonatales en UCI',
    qtyPercentageName: 'neonatalUciBedsPercentage'
  },
  {
    id: 2,
    checkName: 'neonatalUceHosp',
    checkLabel: '¿Tiene habilitado UCE neonatal ?',
    qtyName: 'neonatalUceBedsHosp',
    qtyLabel: 'Camas neonatal UCE',
    qtyLabelOccupation: 'Pacientes neonatales en UCE',
    qtyPercentageName: 'neonatalUceBedsPercentage'
  },
  {
    id: 3,
    checkName: 'pediatricUciHosp',
    checkLabel: '¿Tiene habilitado UCI pediatría?',
    qtyName: 'pediatricUciBedsHosp',
    qtyLabel: 'Camas UCI pediatría',
    qtyLabelOccupation: 'Pacientes pediátricos en UCI',
    qtyPercentageName: 'pediatricUciBedsPercentage'
  },
  {
    id: 4,
    checkName: 'pediatricUceHosp',
    checkLabel: '¿Tiene habilitado UCE pediatría?',
    qtyName: 'pediatricUceBedsHosp',
    qtyLabel: 'Camas UCE pediatría',
    qtyLabelOccupation: 'Pacientes pediátricos en UCE',
    qtyPercentageName: 'pediatricUceBedsPercentage'
  },
  {
    id: 5,
    checkName: 'pediatricBurnHosp',
    checkLabel: '¿Tiene habilitado unidad quemados pediátricos?',
    qtyName: 'pediatricBurnBedsHosp',
    qtyLabel: 'Camas quemados pediátricos',
    qtyLabelOccupation: 'Pacientes pediátricos en el servicio de quemados',
    qtyPercentageName: 'pediatricBurnBedsPercentage'
  },
  // Por ahora toda la unidad de quemados se va a trabajar en adultos
  {
    id: 6,
    checkName: 'pediatricGeneralHosp',
    checkLabel: '¿Tiene habilitado hospitalización general pediátricos?',
    qtyName: 'pediatricGeneralBedsHosp',
    qtyLabel: 'Camas hospitalización general pediátricos',
    qtyLabelOccupation: 'Pacientes pediátricos en hospitalización general',
    qtyPercentageName: 'pediatricGeneralBedsPercentage'
  },
  {
    id: 7,
    checkName: 'adultUciHosp',
    checkLabel: '¿Tiene habilitado UCI adultos?',
    qtyName: 'adultUciBedsHosp',
    qtyLabel: 'Camas UCI adultos',
    qtyLabelOccupation: 'Pacientes adultos en UCI',
    qtyPercentageName: 'adultUciBedsPercentage'
  },
  {
    id: 8,
    checkName: 'adultUceHosp',
    checkLabel: '¿Tiene habilitado UCE adultos?',
    qtyName: 'adultUceBedsHosp',
    qtyLabel: 'Camas UCE adultos',
    qtyLabelOccupation: 'Pacientes adultos en UCE',
    qtyPercentageName: 'adultUceBedsPercentage'
  },
  {
    id: 9,
    checkName: 'adultObstetricHosp',
    checkLabel: '¿Tiene habilitado servicios obstétricos adultos?',
    qtyName: 'adultObstetricBedsHosp',
    qtyLabel: 'Camas hospitalización obstétrica',
    qtyLabelOccupation: 'Pacientes adultos en servicios obstétricos',
    qtyPercentageName: 'adultObstetricBedsPercentage'
  },
  {
    id: 10,
    checkName: 'adultBurnHosp',
    checkLabel: '¿Tiene habilitado unidad quemados adultos?',
    qtyName: 'adultBurnBedsHosp',
    qtyLabel: 'Camas adultos quemados',
    qtyLabelOccupation: 'Pacientes adultos en el servicio de quemados',
    qtyPercentageName: 'adultBurnBedsPercentage'
  },
  {
    id: 11,
    checkName: 'adultGeneralHosp',
    checkLabel: '¿Tiene habilitado hospitalización general adultos?',
    qtyName: 'adultGeneralBedsHosp',
    qtyLabel: 'Camas hospitalización general adultos',
    qtyLabelOccupation: 'Pacientes adultos en hospitalización general',
    qtyPercentageName: 'adultGeneralBedsPercentage'
  }
];

export const urgencyFields = [
  {
    id: 1,
    checkName: 'pediatricGeneralUrg',
    checkLabel: '¿Tiene habilitado pediatría general?',
    qtyName: 'pediatricGeneralBeds',
    qtyLabel: 'Camillas pediatría',
    qtyLabelOccupation: 'Pacientes pediátricos en camillas',
    qtyPercentageName: 'pediatricGeneralBedsPercentage'
  },
  {
    id: 2,
    checkName: 'pediatricSupportUrg',
    checkLabel: '¿Tiene habilitado apoyo terapéutico pediatría?',
    qtyName: 'pediatricSupportBedsUrg',
    qtyLabel: 'Sillas pediatría',
    qtyLabelOccupation: 'Pediátricos en sillas de apoyo terapéutico',
    qtyPercentageName: 'pediatricGeneralBedsPercentage'
  },
  {
    id: 3,
    checkName: 'pediatricReanimationUrg',
    checkLabel: '¿Tiene habilidato sala de reanimación pediátrica?',
    qtyName: 'pediatricReanimationBedsUrg',
    qtyLabel: 'Camillas reanimación pediátrica',
    qtyLabelOccupation: 'Pediátricos en camillas reanimación',
    qtyPercentageName: 'pediatricReanimationBedsPercentage'
  },
  {
    id: 4,
    checkName: 'adultGeneralUrg',
    checkLabel: '¿Tiene habilitado el servicio para adultos?',
    qtyName: 'adultGeneralBedsUrg',
    qtyLabel: 'Camillas para adultos',
    qtyLabelOccupation: 'Pacientes adultos en camillas',
    qtyPercentageName: 'adultGeneralBedsPercentage'
  },
  {
    id: 5,
    checkName: 'adultSupportUrg',
    checkLabel: '¿Tiene habilitado apoyo terapéutico adultos?',
    qtyName: 'adultSupportBedsUrg',
    qtyLabel: 'Sillas adultos',
    qtyLabelOccupation: 'Pacientes adultos en sillas',
    qtyPercentageName: 'adultSupportBedsPercentage'
  },
  {
    id: 6,
    checkName: 'adultReanimationUrg',
    checkLabel: '¿Tiene habilidato sala de reanimación adultos?',
    qtyName: 'adultReanimationBedsUrg',
    qtyLabel: 'Camillas sala de reanimación adultos',
    qtyLabelOccupation: 'Adultos en camillas sala reanimación',
    qtyPercentageName: 'adultReanimationBedsPercentage'
  }
];

export const covidFields = [
  {
    id: 1,
    qtyLabelOccupation: 'Pacientes adultos en urgencias con Covid',
    qtyName: 'covidAdultsPatientUrgency'
  },
  {
    id: 2,
    qtyLabelOccupation: 'Pacientes adultos en UCI con Covid',
    qtyName: 'covidAdultsPatientUci'
  },
  {
    id: 3,
    qtyLabelOccupation: 'Pacientes adultos en UCE con Covid',
    qtyName: 'covidAdultsPatientUce'
  },
  {
    id: 4,
    qtyLabelOccupation: 'Pacientes adultos en hospitalización general con Covid',
    qtyName: 'covidAdultsPatientGeneralHosp'
  },
  {
    id: 5,
    qtyLabelOccupation: 'Intensivistas disponibles',
    qtyName: 'covidIntensivist'
  }
];

export const iraFields = [
  {
    id: 1,
    qtyLabelOccupation: 'Pacientes adultos en urgencias con IRA no Covid',
    qtyName: 'iraAdultsPatientUrgency'
  },
  {
    id: 2,
    qtyLabelOccupation: 'Pacientes adultos en UCI con IRA no Covid',
    qtyName: 'iraAdultsPatientUci'
  },
  {
    id: 3,
    qtyLabelOccupation: 'Pacientes adultos en UCE con IRA no Covid',
    qtyName: 'iraAdultsPatientUce'
  },
  {
    id: 4,
    qtyLabelOccupation: 'Pacientes adultos en hospitalización general con IRA no Covid',
    qtyName: 'iraAdultsPatientGeneralHosp'
  }
];

export const intensivistFields = [
  {
    id: 1,
    qtyLabelOccupation: 'Numero de intensivistas disponibles',
    qtyName: 'intensivistNumber'
  }
];

export const otherOccupationUrgencyFields = [
  // { id: 1, name: 'bedsOutOfServiceUrg', label: 'Camillas fuera de servicio' },
  {
    id: 2,
    name: 'patientsPendingHospitalization',
    label: 'Pacientes con orden de hospitalización'
  }
  // {
  //   id: 3,
  //   name: 'patientsReferredToOtherHospital',
  //   label: 'Pacientes con orden de remisión'
  // },
  // {
  //   id: 4,
  //   name: 'patientsWaitingReferralTransport',
  //   label: 'Pacientes esperando traslado'
  // }
  // {
  //   id: 5,
  //   name: 'maxTimeToBeAttended',
  //   label: 'Tiempo máximo en sala de espera antes de pasar a urgencias(horas)'
  // },
  // {
  //   id: 6,
  //   name: 'maxTimeAtEmergencyToHospitalization',
  //   label: 'Tiempo del paciente más antiguo en urgencias(horas)'
  // }
];

export const occupationNedocsKeys = [
  // 'bedsOutOfServiceInt',
  'patientsPendingHospitalization',
  // 'patientsReferredToOtherHospital',
  // 'patientsWaitingReferralTransport',
  'maxTimeToBeAttended',
  'maxTimeAtEmergencyToHospitalization'
  // 'bedsOutOfServiceUrg'
];

export const redBloodFields = [
  { id: 1, name: 'redbloodCellsOp', label: 'Cantidad de O+' },
  { id: 2, name: 'redbloodCellsOn', label: 'Cantidad de O-' },
  { id: 3, name: 'redbloodCellsBp', label: 'Cantidad de B+' },
  { id: 4, name: 'redbloodCellsBn', label: 'Cantidad de B-' },
  { id: 5, name: 'redbloodCellsAp', label: 'Cantidad de A+' },
  { id: 6, name: 'redbloodCellsAn', label: 'Cantidad de A-' },
  { id: 7, name: 'redbloodCellsABp', label: 'Cantidad de AB+' },
  { id: 8, name: 'redbloodCellsABn', label: 'Cantidad de AB-' }
];

export const plasmaFields = [
  { id: 1, name: 'plasmaOp', label: 'Cantidad de O+' },
  { id: 2, name: 'plasmaOn', label: 'Cantidad de O-' },
  { id: 3, name: 'plasmaBp', label: 'Cantidad de B+' },
  { id: 4, name: 'plasmaBn', label: 'Cantidad de B-' },
  { id: 5, name: 'plasmaAp', label: 'Cantidad de A+' },
  { id: 6, name: 'plasmaAn', label: 'Cantidad de A-' },
  { id: 7, name: 'plasmaABp', label: 'Cantidad de AB+' },
  { id: 8, name: 'plasmaABn', label: 'Cantidad de AB-' }
];

export const patientStates = [
  { id: 1, name: 'Grave', label: 'Grave' },
  { id: 2, name: 'Moderado', label: 'Moderado' },
  { id: 3, name: 'Leve', label: 'Leve' }
];

export const outOfServiceFields = [
  {
    id: 1,
    name: 'neonatalUciHosp',
    label: 'UCI neonatal Internación',
    category: 'hospitalization',
    type: 'neonatal',
    service: 'uci'
  },
  {
    id: 2,
    name: 'neonatalUceHosp',
    label: 'UCE neonatal Internación',
    category: 'hospitalization',
    type: 'neonatal',
    service: 'uce'
  },
  {
    id: 3,
    name: 'pediatricUciHosp',
    label: 'UCI pediatría Internación',
    category: 'hospitalization',
    type: 'pediatric',
    service: 'uci'
  },
  {
    id: 4,
    name: 'pediatricUceHosp',
    label: 'UCE pediatría Internación',
    category: 'hospitalization',
    type: 'pediatric',
    service: 'uce'
  },
  {
    id: 5,
    name: 'pediatricBurnHosp',
    label: 'Unidad quemados pediátricos Internación',
    category: 'hospitalization',
    type: 'pediatric',
    service: 'burn'
  },
  {
    id: 6,
    name: 'pediatricGeneralHosp',
    label: 'Hospitalización general pediátricos Internación',
    category: 'hospitalization',
    type: 'pediatric',
    service: 'general'
  },
  {
    id: 7,
    name: 'adultUciHosp',
    label: 'UCI adultos Internación',
    category: 'hospitalization',
    type: 'adult',
    service: 'uci'
  },
  {
    id: 8,
    name: 'adultUceHosp',
    label: 'UCE adultos Internación',
    category: 'hospitalization',
    type: 'adult',
    service: 'uce'
  },
  {
    id: 9,
    name: 'obstetricGeneralHosp',
    label: 'Servicios obstétricos adultos Internación',
    category: 'hospitalization',
    type: 'obstetric',
    service: 'general'
  },
  {
    id: 10,
    name: 'adultBurnHosp',
    label: 'Unidad quemados adultos Internación',
    category: 'hospitalization',
    type: 'adult',
    service: 'burn'
  },
  {
    id: 11,
    name: 'adultGeneralHosp',
    label: 'Hospitalización general adultos Internación',
    category: 'hospitalization',
    type: 'adult',
    service: 'general'
  },
  {
    id: 12,
    name: 'pediatricGeneralUrg',
    label: 'Pediatría general Urgencias',
    category: 'emergency',
    type: 'pediatric',
    service: 'general'
  },
  {
    id: 14,
    name: 'pediatricReanimationUrg',
    label: 'Sala de reanimación pediátrica Urgencias',
    category: 'emergency',
    type: 'pediatric',
    service: 'reanimation'
  },
  {
    id: 15,
    name: 'adultGeneralUrg',
    label: 'Servicio para adultos Urgencias',
    category: 'emergency',
    type: 'adult',
    service: 'general'
  },
  {
    id: 17,
    name: 'adultReanimationUrg',
    label: 'Sala de reanimación adultos Urgencias',
    category: 'emergency',
    type: 'adult',
    service: 'reanimation'
  }
];

export function translateService(name) {
  const serviceInfo = outOfServiceFields.find(
    service => `${service.category}.${service.type}.${service.service}` === name
  );
  return serviceInfo.label;
}

export const typeTraslate = {
  neonatal: 'neonatal',
  pediatric: 'pediatric',
  adult: 'adult',
  uci: 'Uci',
  uce: 'Uce',
  burn: 'Burn',
  general: 'General',
  obstetric: 'obstetric',
  therapeuticSupport: 'Support',
  reanimation: 'Reanimation',
  emergency: 'Urg',
  hospitalization: 'Hosp'
};

export const reasonsTranslate = {
  isolation: 'Aislamiento',
  humanResource: 'Falta de recurso humano',
  maintenance: 'Mantenimiento'
};
