import React from 'react';
import format from 'date-fns/format';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withAlert } from 'react-alert';
import { Subscribe } from 'unstated';

// Components
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import StepLabel from '@material-ui/core/StepLabel';

// Others
import apiClient from 'config/apiClient';
import { urlApi } from 'config/app';
import './NxTimeline.css';
import AuthContainer from 'containers-state/auth';

const styles = theme => ({
  root: {
    width: '100%'
  },
  stepper: {
    color: 'green',
    padding: '12px 0',
    borderRadius: '6px'
  },
  button: {
    marginRight: theme.spacing(1)
  },
  backButton: {
    marginRight: theme.spacing(1)
  },
  completed: {
    display: 'inline-block'
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  paper: {
    margin: '10px 0',
    padding: '10px 25px'
  }
});

function getSteps({ attentionHours = {}, ambAssignedBy }, isCrue) {
  let patientCareEndDateClass;
  if (ambAssignedBy === 'EPS') {
    patientCareEndDateClass =
      attentionHours.ambulanceDispatchDate && !attentionHours.patientCareEndDate && isCrue ? 'hour-required' : null;
  } else {
    patientCareEndDateClass =
      attentionHours.ipsProfessionalSignatureDate && !attentionHours.patientCareEndDate && isCrue
        ? 'hour-required'
        : null;
  }
  const steps = [
    {
      id: 0,
      name: 'creationCaseDate',
      label: 'Creado',
      date: attentionHours.creationCaseDate,
      isManual: false,
      isOptional: false
    },
    {
      id: 1,
      name: 'validationCaseDate',
      label: 'Aceptado',
      date: attentionHours.validationCaseDate,
      isManual: false,
      isOptional: false
    },
    {
      id: 2,
      name: 'ambulanceDispatchDate',
      label: 'Despachado',
      date: attentionHours.ambulanceDispatchDate,
      isManual: false,
      isOptional: false
    },
    {
      id: 3,
      name: 'eventArrivalDate',
      label: 'Llegada a IPS',
      date: attentionHours.eventArrivalDate,
      isManual: true,
      className:
        attentionHours.ambulanceDispatchDate && !attentionHours.eventArrivalDate && isCrue ? 'hour-required' : null,
      isOptional: true,
      buttonText: 'Confirmar llegada'
    },
    {
      id: 4,
      name: 'transportDate',
      label: 'En traslado',
      date: attentionHours.transportDate,
      isManual: false,
      isOptional: true
    },
    {
      id: 5,
      name: 'ipsArrivalDate',
      label: 'Llegada a IPS destino',
      date: attentionHours.ipsArrivalDate,
      isManual: true,
      className: attentionHours.transportDate && !attentionHours.ipsArrivalDate && isCrue ? 'hour-required' : null,
      isOptional: true,
      buttonText: 'Confirmar llegada'
    },
    {
      id: 6,
      name: 'ipsProfessionalSignatureDate',
      label: 'Recepción del paciente',
      date: attentionHours.ipsProfessionalSignatureDate,
      isManual: false,
      isOptional: true
    },
    {
      id: 7,
      name: 'patientCareEndDate',
      label: 'Confirmación de recepción',
      date: attentionHours.patientCareEndDate,
      isManual: true,
      className: patientCareEndDateClass,
      isOptional: false,
      buttonText: 'Confirmar recepción'
    },
    {
      id: 8,
      name: 'availableDate',
      label: 'Ambulancia disponible',
      date: attentionHours.availableDate,
      isManual: true,
      className: attentionHours.availableDate && !attentionHours.patientCareEndDate && isCrue ? 'hour-required' : null,
      isOptional: true,
      buttonText: 'Habilitar ambulancia'
    }
  ];
  if (ambAssignedBy === 'CRUE' || ambAssignedBy === 'CRUE_DEP') {
    return steps;
  }
  return steps.filter(step => !step.isOptional);
}

class NxTimeline extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0
    };
    this.isCrue = props.roles.includes('CRUE');
  }

  componentWillReceiveProps(nextProps) {
    const { transferInfo } = this.props;
    const nextAttentionHours = (nextProps.transferInfo || {}).attentionHours || {};
    const currentAttentionHours = (transferInfo || {}).attentionHours || {};
    if (nextAttentionHours !== currentAttentionHours) {
      const steps = getSteps(nextProps.transfer || {}, this.isCrueInfo);
      const finishedStep = steps.filter(step => step.date);
      const activeStep = (finishedStep[finishedStep.length - 1] || {}).id || 0;
      this.setState({ activeStep });
    }
  }

  handleStep = step => () => {
    this.setState({
      activeStep: step
    });
  };

  getDescription = step => {
    const { transferInfo } = this.props;
    const ipsOrigin = transferInfo.ipsReq;
    const ipsDestination = transferInfo.ipsRecv || {};
    const ambulance = transferInfo.ambulance ? transferInfo.ambulance : {};
    switch (step.id) {
      case 0:
        return step.date ? `Solicitado por: ${ipsOrigin.name}` : 'No se ha creado nungun caso';
      case 1:
        return step.date
          ? `El traslado ha sido aceptado por ${ipsDestination.name}`
          : 'El traslado no ha sido aceptado aún';
      case 2:
        return step.date
          ? `Se ha asignado ${ambulance.name}(${ambulance.licensePlate}) para realizar el traslado`
          : 'No se ha asignado ningún recurso para el traslado';
      case 3:
        return step.date
          ? `La ambulancía ha llegado al a IPS ${ipsDestination.name}`
          : 'No se ha reportado la llegada de la ambulancia a la IPS';
      case 4:
        return step.date
          ? `El paciente esta siendo trasladado hacia ${ipsDestination.name}`
          : 'No se ha reportado inicio del traslado';
      case 5:
        return step.date
          ? `la ambulancia ha llegado a la IPS ${ipsDestination.name}`
          : 'La ambulancia no ha reportado la llegada del paciente a la IPS de destino';
      case 6:
        return step.date
          ? `El paciente ha llegado a la IPS ${ipsDestination.name}`
          : 'No se ha reportado la llegada del paciente a la IPS de destino';
      case 7:
        return step.date
          ? 'Se ha confirmado la recepción del paciente'
          : 'No se ha confirmado la recepción del paciente ';
      case 8:
        return step.date
          ? `La ambulancia ${ambulance.name}(${ambulance.licensePlate}) se habilitó correctamente`
          : '¿Desea habilitar la ambulancia nuevamente?';

      default:
        break;
    }
    return null;
  };

  assignHour = step => {
    const { transferInfo, saveHours, handleSpinner, alert } = this.props;
    handleSpinner(true);
    const data = {
      dateName: step.name
    };
    apiClient.SEM.patch(`${urlApi}/referral/${transferInfo.id}/attentionHour`, data)
      .then(response => {
        saveHours(response.data.data);
        alert.show('Se ha actualizado el traslado exitosamente', {
          type: 'info',
          timeout: 5000
        });
      })
      .catch(() => {
        alert.show('No se pudo realizar la acción', {
          type: 'error',
          timeout: 5000
        });
      })
      .finally(() => handleSpinner(false));
  };

  getCompletedSteps = steps => {
    return steps.filter(step => !!step.date);
  };

  render() {
    const { classes, transferInfo } = this.props;
    const { activeStep } = this.state;
    const ambAssignedBy = (transferInfo || {}).ambAssignedBy || 'EPS';
    const steps = getSteps(transferInfo || {}, this.isCrue);
    const actualStep = steps.filter(step => step.id === activeStep)[0];
    const prevId = ambAssignedBy === 'EPS' ? 5 : 1;
    const prevState = steps[activeStep - prevId];
    const isRejected = ((transferInfo || {}).referralState || '') === 'REJECTED';
    return (
      <div className={classes.root}>
        <div>
          <Paper
            className={classes.paper}
            elevation={1}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              margin: 0,
              padding: '2px 25px',
              background: '#deebf6'
            }}
          >
            <h5>
              <b>{this.getDescription(actualStep)}</b>
            </h5>
            {actualStep.date && (
              <h5>
                <b>Fecha:</b> {format(new Date(actualStep.date), 'YYYY-MM-DD')}
              </h5>
            )}
            {actualStep.date && (
              <h5>
                <b>Hora:</b> {format(new Date(actualStep.date), 'HH:mm:ss')}
              </h5>
            )}
            {actualStep.isManual && !actualStep.date && this.isCrue && (
              <Button
                onClick={() => this.assignHour(actualStep)}
                disabled={!prevState.date || isRejected}
                variant="contained"
                color="primary"
              >
                {actualStep.buttonText}
              </Button>
            )}
          </Paper>
        </div>
        {transferInfo && (
          <div>
            <Stepper className={classes.stepper} alternativeLabel nonLinear activeStep={activeStep}>
              {steps.map((step, index) => {
                const props = {};
                const labelProps = {};
                if (isRejected && index === 0) {
                  labelProps.optional = (
                    <Typography variant="caption" gutterBottom color="error">
                      El traslado ha sido cancelado
                    </Typography>
                  );
                  props.completed = true;
                }
                if (step.className && !isRejected && this.isCrue) {
                  labelProps.optional = (
                    <Typography variant="caption" gutterBottom color="error">
                      Este paso debe ser completado
                    </Typography>
                  );
                  props.completed = true;
                }
                return (
                  <Step key={step.id} {...props}>
                    <StepButton
                      style={{ margin: '-4px -6px', padding: '4px 6px' }}
                      onClick={this.handleStep(step.id)}
                      completed={!!step.date}
                      {...labelProps}
                    >
                      <StepLabel
                        StepIconProps={{
                          classes: {
                            root: !isRejected ? step.className : null
                          }
                        }}
                      >
                        {step.label}
                      </StepLabel>
                    </StepButton>
                  </Step>
                );
              })}
            </Stepper>
          </div>
        )}
      </div>
    );
  }
}

NxTimeline.propTypes = {
  classes: PropTypes.object,
  transferInfo: PropTypes.object,
  saveHours: PropTypes.func.isRequired,
  handleSpinner: PropTypes.func.isRequired,
  alert: PropTypes.object.isRequired,
  roles: PropTypes.array
};

NxTimeline.defaultProps = {
  classes: {},
  transferInfo: {}
};

const TimeLIne = withAlert()(withStyles(styles)(NxTimeline));

export default props => (
  <Subscribe to={[AuthContainer]}>
    {({ state: { roles: userRoles } }) => <TimeLIne {...props} roles={userRoles} />}
  </Subscribe>
);
