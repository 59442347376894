import React from 'react';
import PropTypes from 'prop-types';
import { Subscribe } from 'unstated';

// @material-ui/core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

// core components
import AuthContainer from 'containers-state/auth';
import { WrapperFloatProvider } from 'context-api/WrapperFloatContext';
import ListWrapperFloat from 'nxComponents/WrapperFloat/ListWrapperFloat';
import { Redirect } from 'react-router-dom';

const MapProvider = React.lazy(() => import('context-api/MapContext'), 'default');
const MapContainer = React.lazy(() => import('../../nxComponents/MapContainer/MapContainer'));
const DashboardIps = React.lazy(() => import('./DashboardIps'));
const DashboardBloodBank = React.lazy(() => import('./DashboardBloodBank'));

const RenderContent = props => {
  const { roles, location } = props;
  if (!roles) {
    return null;
  }
  if (['CRUE', 'CRUE_REFERRAL_MANAGER', 'CRUE_RESOURCES_AUDITOR'].some(r => roles.includes(r))) {
    const urlParams = new URLSearchParams(location.search);
    const params = {
      ambulanceId: urlParams.get('ambulanceId'),
      ipsReqId: urlParams.get('ipsReqId')
    };
    return (
      <GridItem xs={12} sm={12} md={12}>
        <ListWrapperFloat />
        <React.Suspense fallback={<div>Loading...</div>}>
          <MapProvider>
            <MapContainer params={params} />
          </MapProvider>
        </React.Suspense>
      </GridItem>
    );
  }
  if (
    ['EPS_REFERRAL_MANAGER', 'EPS', 'TRANSPORT_REFERRAL_MANAGER', 'CRUE_DEP', 'CRUE_DEP_REFERRAL_MANAGER'].some(r =>
      roles.includes(r)
    )
  ) {
    return <Redirect to="/traslados/en_progreso" />;
  }
  if (roles.includes('IPS_BLOOD_RESOURCES')) {
    return (
      <React.Suspense fallback={<div>Loading...</div>}>
        <DashboardBloodBank {...props} />
      </React.Suspense>
    );
  }
  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <DashboardIps />
    </React.Suspense>
  );
};

RenderContent.propTypes = {
  roles: PropTypes.array,
  location: PropTypes.object
};

export function Dashboard(props) {
  const { roles } = props;
  return (
    <WrapperFloatProvider roles={roles}>
      <GridContainer>{RenderContent(props)}</GridContainer>
    </WrapperFloatProvider>
  );
}

Dashboard.propTypes = {
  roles: PropTypes.array
};

export default props => (
  <Subscribe to={[AuthContainer]}>
    {AuthContainer => <Dashboard {...props} roles={AuthContainer.state.roles} />}
  </Subscribe>
);
