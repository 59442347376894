import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Input from '@material-ui/core/Input';
import PropTypes from 'prop-types';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import intersection from 'lodash/intersection';
import { customSelectStyles } from 'assets/jss/material-dashboard-pro-react';

const useStyles = makeStyles(customSelectStyles);

function FilterSelect({ roles, getFilteredList }) {
  const urlParams = new URLSearchParams(window.location.search);
  const statusFilter = urlParams.get('state') ? urlParams.get('state') : 'pending';
  // ToDo: por props traer el metodo que actualiza el listado para llamarlo
  const classes = useStyles();
  const [filter, setFilter] = React.useState({
    status: statusFilter,
    search: null
  });
  // const inputLabelRef = React.useRef(null);

  React.useEffect(
    () => {
      // toDO:

      /*  this.props.history.replace({
          search: `?currentPage=${currentPage}&pageSize=${this.state.pageSize}`
        });
      };
    */
      getFilteredList(filter);
    },
    [filter]
  );

  function handleChange(event) {
    setFilter({
      ...filter,
      [event.target.name]: event.target.value
    });
  }

  return (
    <FormControl className={classes.formControl}>
      <Select
        disableUnderline
        className={classes.selectEmpty}
        value={filter.status || ''}
        onChange={handleChange}
        input={<Input name="status" id="status" />}
        autoWidth
      >
        <MenuItem value={null} disabled>Estado</MenuItem>
        <MenuItem value="pending">Pendientes</MenuItem>

        {(roles || []).includes('IPS_REFERRAL_REPORTER') && <MenuItem value="requested">Solicitados</MenuItem>}
        {intersection(roles, ['IPS_REFERRAL_REPORTER', 'TRANSPORT_REFERRAL_MANAGER']).length > 0 && (
          <MenuItem value="assigned">Asignados</MenuItem>
        )}
        {intersection(roles, ['IPS_REFERRAL_REPORTER', 'TRANSPORT_REFERRAL_MANAGER']).length > 0 && (
          <MenuItem value="ended">Finalizados</MenuItem>
        )}

        {/* intersection(roles, ['EPS', 'CRUE']).length > 0 && <MenuItem value="pending">Pendientes</MenuItem> */}
        {intersection(roles, ['EPS', 'CRUE', 'CRUE_DEP']).length > 0 && <MenuItem value="ended">Finalizados</MenuItem>}
      </Select>
    </FormControl>
  );
}

export default FilterSelect;

FilterSelect.propTypes = {
  roles: PropTypes.array,
  getFilteredList: PropTypes.func.isRequired
};
