import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import FaSearch from 'react-icons/lib/fa/search';
// import FaComments from 'react-icons/lib/fa/comments';
// import FaClose from 'react-icons/lib/fa/close';
// import FaMenu from 'react-icons/lib/md/more-vert';
import { makeStyles, withStyles } from '@material-ui/styles';

import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
// import IconButton from '@material-ui/core/IconButton';
// import MenuIcon from '@material-ui/icons/Menu';

import MessageList from 'nxComponents/nxChatElements/MessageList/MessageList';
import Input from 'nxComponents/nxChatElements/Input/Input';
import Button from 'nxComponents/nxChatElements/Button/Button';
import TransferContainer from 'containers-state/transferState';

import { Subscribe } from 'unstated';
import AuthContainer from 'containers-state/auth';
import { actionButton } from 'assets/jss/material-dashboard-pro-react';
import ChatContainer from '../../containers-state/chatState';
import SimpleSelect from './Select';
import { isIpsRecipient, isIpsApplicant, isTransportEntity } from '../../utils/Transfer';
// // RCE CSS
import 'nxComponents/nxChatElements/main.css';
import './chatStyle.css';

const MapProvider = React.lazy(() => import('context-api/MapContext'), 'default');

const chatStyles = {
  chatRoot: {
    position: 'absolute',
    top: 0,
    height: '100%',
    width: '100%',
    background: '#FFFFFF'
  },
  actionButton,
  bottomBar: {
    borderTop: 'solid 1px #eee',
    background: '#ffffff',
    gap: '7px',
    display: 'flex',
    flexFlow: 'row wrap',
    padding: '20px',
    zIndex: 2,
    '& .chatInputContainer': {
      flex: '1 0 70%'
    },
    '& .chatInputActions': {
      display: 'flex',
      gap: '7px',
      justifyContent: 'end',
      flex: 1
    }
  }
};
export class Chat extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = { msg: '', time: 0 };
    this.timerId = null;
    // this.props.chatData.bindAuth(this.props.authData)
    props.chatData.initialize(props.match.params.transferId);
  }

  componentDidMount() {
    // const { chatData, match } = this.props;
    // chatData.getMessagesForTransfer(match.params.transferId);
  }

  componentDidUpdate(prevProps) {
    const { match, chatData } = this.props;
    if (parseInt(match.params.transferId, 10) !== parseInt(prevProps.match.params.transferId, 10)) {
      chatData.initialize(match.params.transferId);
      // chatData.getMessagesForTransfer(match.params.transferId);
    }
  }

  handleChange = event => {
    this.setState({
      msg: event.target.value
    });
  };

  getTitle = () => {
    const { transfer = {} } = this.props;
    if (!transfer) return '';

    const { network, patient, id } = transfer;
    if (!network || !patient || !id) return '';
    const name = `${patient.person.firstName || ''} ${patient.person.middleName || ''} ${
      patient.person.lastName
    } ${patient.person.secondLastName || ''}`;
    return `Traslado #${id}: ${name}-${network.name}`;
  };

  setTextInputRef = element => {
    this.textInput = element;
  };

  onKeySendPress = e => {
    const { msg } = this.state;
    if (msg.trim() === '') return true;
    const { chatData, match } = this.props;
    if (e.shiftKey && e.charCode === 13) {
      return true;
    }
    if (e.charCode === 13) {
      chatData.addMessage(match.params.transferId, msg);
      this.inputRef.current.clear();
      this.setState({ msg: '' });
      e.preventDefault();
      return false;
    }
    return true;
  };

  showChatInput = () => {
    const { authData, transfer = {} } = this.props;
    const { roles, userInfo } = authData.state;
    const entityId = userInfo.attributes.entityId ? userInfo.attributes.entityId[0] : undefined;
    if (
      (transfer || {}).ipsRecv &&
      roles.includes('IPS_REFERRAL_REPORTER') &&
      (!isIpsApplicant(entityId, transfer) && !isIpsRecipient(entityId, transfer))
    ) {
      return false;
    }
    if (
      (transfer || {}).ambulancesEntityId &&
      roles.includes('TRANSPORT_REFERRAL_MANAGER') &&
      !isTransportEntity(entityId, transfer)
    ) {
      return false;
    }
    if (
      ((transfer || {}).ambAssignedBy === 'CRUE' || (transfer || {}).ambAssignedBy === 'CRUE_DEP') &&
      isTransportEntity(entityId, transfer)
    ) {
      return false;
    }
    return true;
  };

  render() {
    const { msg } = this.state;
    const { chatData, match, transfer, authData, classes } = this.props;
    const { roles } = authData.state;
    const { messageList } = chatData.state;
    const isReferralEnded =
      (transfer || {}).referralState === 'REJECTED' || (transfer || {}).referralState === 'REFERRAL_ENDED';
    return (
      <div
        className="container"
        style={{ flex: '1 1 auto', display: 'flex', flexFlow: 'column', position: 'relative', maxHeight: '55vh' }}
      >
        <div className={classes.chatRoot} data-asset-chat-background="true" />
        <MessageList className="message-list" lockable downButtonBadge={10} dataSource={messageList} />

        {isReferralEnded || !this.showChatInput() ? null : (
          <div className={classes.bottomBar}>
            <div className="chatInputContainer">
              <Input
                maxlength={999}
                multiline
                placeholder="Mensaje..."
                onChange={this.handleChange}
                ref={this.inputRef}
                onKeyPress={e => {
                  this.onKeySendPress(e);
                }}
                style={{ marginBottom: '50px' }}
              />
            </div>
            <div className="chatInputActions">
              {['CRUE', 'CRUE_DEP'].some(r => roles.includes(r)) ? (
                <React.Suspense fallback={<div>Loading...</div>}>
                  <MapProvider>
                    <SimpleSelect />
                  </MapProvider>
                </React.Suspense>
              ) : (
                <SimpleSelect />
              )}
              <Button
                className={classes.actionButton}
                text="Enviar"
                onClick={() => {
                  if (msg.trim() === '') return;
                  chatData.addMessage(match.params.transferId, msg);
                  this.inputRef.current.clear();
                  this.setState({ msg: '' });
                }}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

// ToDo verificar el tipo de dato de los propTypes
Chat.propTypes = {
  chatData: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  transfer: PropTypes.object,
  authData: PropTypes.object,
  classes: PropTypes.object
};

const StyedChat = withStyles(chatStyles)(Chat);

export default props => (
  <Subscribe to={[ChatContainer, AuthContainer, TransferContainer]}>
    {(chatData, authData, { state: { currentTransfer: transfer } }) => (
      <StyedChat {...props} chatData={chatData} authData={authData} transfer={transfer} />
    )}
  </Subscribe>
);

const useStyles = makeStyles({
  root: {
    background: 'rgba(206, 203, 203, 0.5)',
    boxShadow: '0 3px 5px 2px rgba(165, 152, 155, 0.3)',
    borderRadius: '3px'
  },
  label: {
    textTransform: 'capitalize'
  }
});

function ChatToolBar({ chatIntegrants = [], title = '', time }) {
  const classes = useStyles();
  return (
    <Toolbar
      classes={{
        root: classes.root // class name, e.g. `classes-nesting-root-x`
        // label: classes.label, // class name, e.g. `classes-nesting-label-x`
      }}
      style={{ display: 'flex', justifyContent: 'space-between' }}
    >
      {/* <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="Menu">
        <MenuIcon />
      </IconButton> */}
      <div style={{ width: '100%' }}>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6" /*  className={classes.title} */>{title}</Typography>
          {time !== 0 && <Typography variant="h6">{`${time} minutos`}</Typography>}
        </div>
        {chatIntegrants.length > 0 && (
          <Typography variant="body1" /*  className={classes.title} */>
            Integrantes:{' '}
            {chatIntegrants.map(integrant => {
              return <span style={{ fontSize: '0.85em', padding: '0 5px' }}>{integrant}</span>;
            })}
          </Typography>
        )}
      </div>
    </Toolbar>
  );
}

ChatToolBar.propTypes = {
  time: PropTypes.number.isRequired,
  chatIntegrants: PropTypes.array,
  title: PropTypes.string
};
