import React from 'react';
import ViewDataTransfer from '../../nxComponents/Transfer/ViewDataTransfer2';
import Chat from '../../nxComponents/Chat/Chat';

export function TransferDetail(props) {
  return (
    <div>
      <ViewDataTransfer {...props} />
      <Chat {...props} />
    </div>
  );
}

// export default Transfer;

export default TransferDetail;
