import React, { useState } from 'react';
import { Subscribe } from 'unstated';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import AuthContainer from '../../containers-state/auth';
import authActionsStyles from './styles';
import Hidden from '@material-ui/core/Hidden';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(authActionsStyles);

const AuthActionsMenu = ({ userInfo, logout, hideSm, hideMd }) => {
  const classes = useStyles();
  const [openAvatar, setOpenAvatar] = useState(false);
  const toggleAvatar = () => setOpenAvatar(value => !value);
  const hideAvatar = () => setOpenAvatar(false);

  return (
    <Hidden smDown={hideSm} mdUp={hideMd}>
      <ClickAwayListener onClickAway={hideAvatar}>
        <List className={classes.root}>
          <ListItem button onClick={toggleAvatar}>
            <ListItemText
              primary={userInfo ? userInfo.username : 'usuario'}
              disableTypography={true}
            />
            { openAvatar ? <ExpandLess /> : <ExpandMore /> }
          </ListItem>
          <Collapse in={openAvatar} unmountOnExit>
            <List>
              <ListItem button>
                <ListItemText
                  primary="Cerrar sesión"
                  disableTypography={true}
                  onClick={logout ? logout : null}
                />
              </ListItem>
            </List>
          </Collapse>
        </List>
      </ClickAwayListener>
    </Hidden>
  );
}

export default props => (
  <Subscribe to={[AuthContainer]}>
    {AuthContainer => (
      <AuthActionsMenu
        {...props}
        logout={AuthContainer.logout}
        userInfo={AuthContainer.state.userInfo}
      />
    )}
  </Subscribe>
);
