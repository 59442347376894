import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';

const useStyles = makeStyles({
  root: {
    borderRadius: '3px',
    display: 'flex',
    justifyContent: 'space-between',
    background: '#3f50b5',
    color: '#ffffff',
    margin: 0
  },
  label: {
    textTransform: 'capitalize'
  }
});

function TransferHeader({ chatIntegrants = [], title = '', time }) {
  const classes = useStyles();
  return (
    <Toolbar
      classes={{
        root: classes.root // class name, e.g. `classes-nesting-root-x`
        // label: classes.label, // class name, e.g. `classes-nesting-label-x`
      }}
    >
      {/* <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="Menu">
        <MenuIcon />
      </IconButton> */}
      <div style={{ width: '100%' }}>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', fontSize: '0.85em' }}>
          <Typography variant="h6" /*  className={classes.title} */>{title}</Typography>
          {time !== 0 && <Typography variant="h6">{`${time} minutos`}</Typography>}
        </div>
        {chatIntegrants.length > 0 && (
          <Typography variant="body1" /*  className={classes.title} */>
            Integrantes:{' '}
            {chatIntegrants.map(integrant => {
              return <span style={{ fontSize: '0.85em', padding: '0 5px' }}>{integrant}</span>;
            })}
          </Typography>
        )}
      </div>
    </Toolbar>
  );
}

TransferHeader.propTypes = {
  time: PropTypes.number.isRequired,
  chatIntegrants: PropTypes.array,
  title: PropTypes.string
};

export default TransferHeader;
