import { Container } from 'unstated';
import socketSEM from 'config/ws';

class NotificatiosnContainer extends Container {
  constructor(props) {
    super(props);

    this.state = {
      notifications: []
    };

    socketSEM.on('notification', data => {
      this.checkRefreshReferral(data);
      if (data.eventType !== 'ONLY_NOTIFY') this.addNotification(data);
    });

    socketSEM.on('getNotifications', data => {
      const notificacionsParse = data; /* data.map(notification => JSON.parse(notification)); */
      this.setNotifications(notificacionsParse);
    });

    this.id = 0;
  }

  setNotifications = notifications => {
    this.setState({ notifications });
  };

  checkRefreshReferral = data => {
    const pathName = window.location.pathname;
    if (pathName.match(/traslados/)) {
      const urlSplit = pathName.split('/traslados/');
      const urlId = pathName.split('/traslados/')[urlSplit.length - 1];
      if (parseInt(urlId, 10) === parseInt((((data || {}).metadata || {}).referral || {}).id || -1, 10)) {
        this.transfer.getTransferId(urlId);
      }
    }
  };

  addNotification = notification => {
    const { notifications } = this.state;
    const newNotification = {
      // id: this.id++,
      marked: false,
      ...notification
    };
    // notifications.unshift(newNotification)
    this.setState({
      notifications: [newNotification, ...notifications]
    });
  };

  bindTransfer(transfer) {
    this.transfer = transfer;
  }

  removeNotification = id => {
    this.setState({
      notifications: this.state.notifications.filter(notification => notification.id !== id)
    });
  };

  markNotification = id => {
    this.setState({
      notifications: this.state.notifications.map(notification => {
        if (notification.id !== id) {
          return notification;
        }
        return { ...notification, marked: !notification.marked };
      })
    });
  };
}

export default NotificatiosnContainer;
