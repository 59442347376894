import io from 'socket.io-client';
import { urlwsChat } from './app';

const socketChatConnection = io(urlwsChat, {
  path: {
    local: '',
    staging: '/semenvigado/chat',
    production: '/chat'
  }[process.env.REACT_APP_ENV || 'local'],
  autoConnect: false,
  forceNew: true
});

export default socketChatConnection;
