// @flow

import React from 'react';
import format from 'date-fns/format';
import PropTypes from 'prop-types';
import { withAlert } from 'react-alert';
import apiClient from 'config/apiClient';
import TransferContainer from 'containers-state/transferState';
import AuthContainer from 'containers-state/auth';
import { urlApi } from 'config/app';
import { Subscribe } from 'unstated';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import DialogActions from '@material-ui/core/DialogActions';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

// material ui icons

// Styles
import validationFormsStyle from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle';
import buttonStyle from 'assets/jss/material-dashboard-pro-react/components/buttonStyle';

// core components
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import Tooltip from '@material-ui/core/Tooltip';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import NxTimeline from 'nxComponents/NxTimeline/NxTimeline';
import { differenceInMinutes } from 'date-fns';
import NxSpinner from '../NxSpinner/NxSpinner';
import { typeDocuments } from '../../variables/person';
import { referralReason as transferReason } from '../../variables/transfer';
import TransferHeader from './TransferHeader';

// Others


const styles = theme => ({
  ...validationFormsStyle,
  columnsContainer: {
    columnCount: 2
  },
  trasnferDetailsCard: {
    marginTop: 0,
    marginBottom: 0,
    padding: '0 20px',
    position: 'absolute',
    border: 'solid 1px #fff',
    zIndex: 3,
    borderRadius: '0 0 20px 20px'
  },
  root: {
    width: '100%',
    '& .TransferDetailsPanel': {
      background: '#bdd7ee',
      '& .MuiExpansionPanelDetails-root': {
        padding: 0
      },
      '& .MuiExpansionPanel-root .Mui-expanded': {
        padding: 0
      }
    },
    '& .MuiExpansionPanel-root.Mui-expanded': {
      margin: 0
    }
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 600
  },
  titleInfo: {
    margin: 0,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  itemTitle: {
    padding: '0.5em 0 0.5em 0',
    fontWeight: 800
  },
  itemValue: {
    margin: 0,
    padding: '0.5em 0 0.5em 0'
  },
  gridDl_dt: {
    borderTop: '2px solid #ccc',
    padding: '0.5em 0 0.5em 0',
    fontWeight: 600
  },
  gridDl_dd: {
    margin: 0,
    padding: '0.5em 0 0.5em 0'
  },
  gridDl_dt_dd: {
    margin: 0,
    padding: '0.5em 0 0.5em 0'
  },
  '@media (min-width: 550px)': {
    gridDl: {
      display: 'grid',
      gridTemplateColumns: '1fr 2fr',
      gridTemplateRows: 'auto'
    },
    gridDl_dt: {
      gridColumnStart: 1
    },
    gridDl_dd: {
      gridColumnStart: 2,
      margin: 0
    },
    gridDl_dt_dd: {
      gridColumnStart: 2, // ToDo: buscar el plugin extends de JSS
      margin: 0,
      borderTop: '2px solid #ccc'
    }
  },
  ...buttonStyle
});

const capitalize = s => {
  if (typeof s !== 'string') return s;
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
};

export class ViewDataTransfer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      openAccordion: false,
      time: 0
    };
  }

  componentDidMount() {
    this.getTransfer();
  }

  componentDidUpdate(prevProps) {
    const { transferData } = this.props;
    const transfer = transferData.state.currentTransfer;
    const previousTransfer = prevProps.transferData.state.currentTransfer;
    if (transfer !== previousTransfer && !['REFERRAL_ENDED', 'REJECTED'].includes(transfer.referralState)) {
      this.calculateTime(new Date(), transfer.createdAt);
      this.timerId = setInterval(() => this.calculateTime(new Date(), transfer.createdAt), 60000);
    }
  }

  componentWillUnmount() {
    const { transferData } = this.props;
    transferData.setCurrentTransfer(null);
  }

  handleSpinner = boolean => this.setState({ loading: boolean });

  getTransfer = () => {
    const { transferData, match } = this.props;
    const trasferId = match.params.transferId;
    transferData
      .getTransferId(trasferId)
      .then(() => {
        this.getFiles(trasferId);
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  getFiles = trasferId => {
    const { alert } = this.props;
    apiClient.SEM.get(`${urlApi}/file/referral/${trasferId}`)
      .then(({ data }) => {
        this.setState({
          attach9Url: data.data.attach9Url,
          diagnosesUrls: data.data.diagnosesUrls
        });
      })
      .catch(() => {
        alert.show('No se encontró información del traslado.', {
          type: 'error'
        });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  handleCloseAccordion = () => {
    const { openAccordion } = this.state;
    if (openAccordion) {
      const container = document.querySelector('#scrollComponent');
      container.scrollTo(0, 0);
      this.setState({ openAccordion: false });
    }
  };

  handleChangeAccordion = () => {
    this.setState(prevState => {
      return { openAccordion: !prevState.openAccordion };
    });
  };

  valid = date => date.isBefore(new Date());

  getTitle = () => {
    const { transferData } = this.props;
    const transfer = transferData.state.currentTransfer;
    if (!transfer) return '';

    const { network, patient, id } = transfer;
    if (!network || !patient || !id) return '';
    const name = [
      patient.person.firstName,
      patient.person.middleName,
      patient.person.lastName,
      patient.person.secondLastName
    ]
      .filter(namePart => namePart && namePart.trim())
      .join(' ');
    return `Traslado #${id}: ${name}-${network.name}`;
  };

  calculateTime(finalDate, initialDate) {
    const time = differenceInMinutes(finalDate, initialDate);
    this.setState({ time });
  }

  render() {
    const { classes = {}, transferData, roles } = this.props;
    if (!transferData.state.currentTransfer) {
      return <NxSpinner loading />;
    }
    const {
      patient,
      network,
      referralReason,
      observations,
      ambulanceType,
      createdAt
    } = transferData.state.currentTransfer;
    const { attach9Url, diagnosesUrls, loading, openAccordion, time } = this.state;
    const sex = patient && patient.person.sex === 'M' ? 'Masculino' : 'Femenino';
    const transferDisplayData = [
      {
        title: 'Fecha de la solicitud',
        value: format(createdAt, 'DD-MM-YYYY  hh:mm a')
      },
      {
        title: 'EPS',
        value: patient ? capitalize(`${patient.insuranceRegime.eps.name}`) : ''
      },
      {
        title: 'Nombre Completo',
        value: patient
          ? `${patient.person.firstName || ''} ${patient.person.middleName || ''} ${patient.person.lastName} ${patient
              .person.secondLastName || ''}`
          : ''
      },
      {
        title: 'Régimen de seguridad social',
        value: patient ? capitalize(`${patient.insuranceRegime.regimeType.name}`) : ''
      },
      {
        title: 'Tipo de documento',
        value:
          patient && typeDocuments.find(type => type.value === patient.person.documentType)
            ? typeDocuments.find(type => type.value === patient.person.documentType).name
            : ''
      },
      {
        title: 'Número de documento',
        value: patient ? `${patient.person.document}` : ''
      },
      {
        title: 'Red',
        value: network ? `${network.name}` : ''
      },
      {
        title: 'Sexo',
        value: sex
      },
      {
        title: 'Triage',
        value: patient ? `${patient.triage}` : ''
      },
      {
        title: 'Fecha de nacimiento',
        value: patient ? format(new Date(patient.person.birthdate), 'DD-MM-YYYY') : ''
      },
      {
        title: 'Tipo de ambulancia',
        value: ambulanceType || '',
        hide: !ambulanceType
      },
      {
        title: 'Dirección',
        value: patient ? `${patient.person.address || 'Sin información'}` : ''
      },
      {
        title: 'Motivo del traslado',
        value: transferReason.find(reason => reason.value === referralReason)
          ? transferReason.find(reason => reason.value === referralReason).name
          : ''
      },
      {
        title: 'Teléfono o celular',
        value: patient ? `${patient.person.phone || 'Sin información'}` : ''
      },
      {
        title: 'Diagnosticos',
        value: patient && patient.diagnoses.map(diagnose => capitalize(diagnose.cie10.name)).join(', ')
      },
      {
        title: '¿Esta en embarazo?',
        value: patient ? `${patient.isPregnant ? 'Si' : 'No'}` : '',
        hide: sex !== 'Femenino'
      },
      {
        title: 'Observaciones generales',
        value: observations || 'Sin información'
      }
    ];
    return (
      <div className={classes.root}>
        <NxSpinner loading={loading} />
        <TransferHeader title={this.getTitle()} time={time} />
        <NxTimeline
          transferInfo={transferData.state.currentTransfer}
          saveHours={transferData.saveHours}
          handleSpinner={this.handleSpinner}
        />
        <ClickAwayListener onClickAway={this.handleCloseAccordion}>
          <ExpansionPanel
            expanded={openAccordion}
            className="TransferDetailsPanel"
            onChange={this.handleChangeAccordion}
          >
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1" className={classes.heading}>
                Información de la solicitud
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Card className={classes.trasnferDetailsCard}>
                <CardBody>
                  <GridContainer spacing={3}>
                    {transferDisplayData.map(item =>
                      item.hide ? null : (
                        <GridItem xs={12} md={6} style={{ marginTop: '10px' }}>
                          <GridContainer>
                            <GridItem xs={12} sm={5}>
                              <span className={classes.itemTitle}>{item.title}</span>
                            </GridItem>
                            <GridItem xs={12} sm={7}>
                              <span className={classes.itemValue}>{item.value}</span>
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                      )
                    )}
                  </GridContainer>
                  {!roles.includes('TRANSPORT_REFERRAL_MANAGER') && (
                    <DialogActions
                      style={{
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    >
                      {diagnosesUrls &&
                        transferData.state.currentTransfer.diagnosesResources &&
                        diagnosesUrls.map((url, index) => (
                          <Tooltip key={index} title={transferData.state.currentTransfer.diagnosesResources[index]}>
                            <a className={[classes.button, classes.primary]} href={url} target="_new">
                              {`Descargar archivo ${index + 1}`}
                            </a>
                          </Tooltip>
                        ))}
                      <Tooltip title="Descargar anexo 9">
                        <a className={[classes.button, classes.primary]} href={attach9Url} target="_new">
                          Descargar anexo 9
                        </a>
                      </Tooltip>
                    </DialogActions>
                  )}
                </CardBody>
              </Card>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </ClickAwayListener>
      </div>
    );
  }
}

ViewDataTransfer.propTypes = {
  classes: PropTypes.object.isRequired,
  alert: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
  transferData: PropTypes.object,
  match: PropTypes.object,
  transfer: PropTypes.object
};
// export default withAlert(withStyles(styles)(ViewDataTransfer));
const ViewDataTransferStyle = withAlert()(withStyles(styles)(ViewDataTransfer));
export default props => (
  <Subscribe to={[AuthContainer, TransferContainer]}>
    {({ state: { roles: userRoles } }, { state, getTransferId, setCurrentTransfer, saveHours }) => (
      <ViewDataTransferStyle
        {...props}
        transferData={{ getTransferId, state, setCurrentTransfer, saveHours }}
        roles={userRoles}
      />
    )}
  </Subscribe>
);
