const authActionsStyles = {
  root: {
    minWidth: '10rem',
    '& .MuiCollapse-container': {
      position: 'absolute',
      background: '#eee',
      zIndex: 2,
      width: '100%',
      '& .MuiListItem-gutters': {
        paddingTop: 0,
        paddingBottom: 0,
      }
    }
  }
};

export default authActionsStyles;

