// @flow

import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import { SearchState, IntegratedFiltering } from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  VirtualTable,
  Toolbar,
  SearchPanel,
  TableBandHeader,
  TableColumnVisibility,
  ColumnChooser
} from '@devexpress/dx-react-grid-material-ui';

import { TableComponent } from './TableComponentBase';
// import { TableRow } from './RowComponent';
import { BandCellBase } from './BandCellHeader';
import { Cell } from './CellComponent';
import { HeaderCellBase } from './HeaderCellBase';

const Root = props => <Grid.Root {...props} style={{ height: '100%' }} />;

const styles = theme => ({
  cell: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1em'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.2em'
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '1.4em'
    }
  }
});

function TableTransfersResources(props) {
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [searchValue, setSearchValue] = useState();
  const { dataTable = [], config, classes } = props;

  const handleSearch = useCallback(value => setSearchValue(value), []);
  const handleColumns = useCallback(hiddenColumnNames => setHiddenColumns(hiddenColumnNames), []);

  return (
    <Paper>
      <Grid rows={dataTable} columns={config.columns} rootComponent={Root}>
        <VirtualTable height="auto" />
        <SearchState value={searchValue} onValueChange={handleSearch} />
        <IntegratedFiltering />

        <VirtualTable
          estimatedRowHeight={100}
          columnExtensions={config.columnExtensions}
          cellComponent={prop => <Cell {...prop} classes={classes} />}
          tableComponent={TableComponent}
        />
        <TableHeaderRow cellComponent={HeaderCellBase} />
        <TableColumnVisibility hiddenColumnNames={hiddenColumns} onHiddenColumnNamesChange={handleColumns} />
        <Toolbar />
        <SearchPanel />
        <ColumnChooser />
        <TableBandHeader
          columnBands={config.columnBands}
          cellComponent={prop => <BandCellBase {...prop} classes={classes} />}
        />
      </Grid>
    </Paper>
  );
}

TableTransfersResources.propTypes = {
  config: PropTypes.object.isRequired,
  dataTable: PropTypes.array,
  classes: PropTypes.object
};

export default withStyles(styles, { name: 'TransfersResources' })(TableTransfersResources);
