import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withAlert } from 'react-alert';
import moment from 'moment';
import { format, getDate, setDate } from 'date-fns';
import apiClient from 'config/apiClient';
import AuthContainer from 'containers-state/auth';
import { Subscribe } from 'unstated';
import queryString from 'query-string';

// @material-ui/core
import withStyles from '@material-ui/core/styles/withStyles';
import FormControl from '@material-ui/core/FormControl';
// Assets
import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import { actionButton, blankCardHeader, customSelectStyles } from 'assets/jss/material-dashboard-pro-react';

// Components
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from 'components/CustomButtons/Button';
import { isCrue, isIps } from 'utils/authFuntions';
import OccupationReport from './OccupationReport';
import Annex5Report from './Annex5Report';
import UserSafixReport from './UserSafixReport';
import BillSafixReport from './BillSafixReport';
import TransferReport from './TransferReport';
import NxSpinner from '../NxSpinner/NxSpinner';

import { urlApi } from '../../config/app';

// import { GridList } from '@material-ui/core';

const FileDownload = require('js-file-download');

const valid = current => {
  return current.isBefore(moment());
};

const validFinalDate = (finalDate, initialDate) => {
  const oneMonth = moment(initialDate).add(6, 'months');
  const today = moment();
  return finalDate.isBetween(initialDate, today) && finalDate.isBefore(oneMonth);
};

const validOnlyOneMonth = (finalDate, initialDate) => {
  const oneMonth = moment(initialDate).add(1, 'months');
  const today = moment();
  return finalDate.isBetween(initialDate, today) && finalDate.isBefore(oneMonth);
};

const reportsStyles = {
  ...dashboardStyle,
  actionButton,
  blankCardHeader,
  selectStyles: {
    ...customSelectStyles.formControl,
    maxWidth: '300px'
  }
};

const ReportsCOT = React.memo(function ResourcesReport(props) {
  const [state, setState] = useState({
    reportType: '',
    ipsList: [],
    attentionNetworkList: [],
    ambulanceEntityList: [],
    epsList: []
  });

  const { classes } = props;

  const defaultState = {
    initialDate: undefined,
    finalDate: undefined,
    ips: undefined,
    eps: undefined,
    ipsReceives: undefined,
    ipsRequesting: undefined,
    ipsForIps: undefined,
    triage: undefined,
    attentionNetwork: undefined,
    ambulanceEntity: undefined,
    loading: false
  };

  function getAllIps() {
    apiClient.SEM.get(`${urlApi}/ips`)
      .then(({ data }) => {
        data.data.rows.push({
          id: 'all',
          name: 'TODAS'
        });
        setState(prevState => {
          return {
            ...prevState,
            ipsList: data.data.rows
          };
        });
      })
      .catch(error => {
        console.error(error);
      });
  }

  function getAllEps() {
    apiClient.SEM.get(`${urlApi}/eps`)
      .then(({ data }) => {
        data.data.rows.push({
          id: 'all',
          name: 'TODAS'
        });
        setState(prevState => {
          return {
            ...prevState,
            epsList: data.data.rows
          };
        });
      })
      .catch(error => {
        console.error(error);
      });
  }

  function getAmbulanceEntityList() {
    apiClient.SEM.get(`${urlApi}/ambulance/entities`)
      .then(({ data }) => {
        data.data.push({
          id: 'all',
          name: 'TODAS'
        });
        setState(prevState => {
          return {
            ...prevState,
            ambulanceEntityList: data.data
          };
        });
      })
      .catch(error => {
        console.error(error);
      });
  }

  function getAttentionNetwork() {
    apiClient.SEM.get(`${urlApi}/network`)
      .then(({ data }) => {
        setState(prevState => {
          data.data.rows.push({
            id: 'all',
            name: 'TODAS'
          });
          return {
            ...prevState,
            attentionNetworkList: data.data.rows
          };
        });
      })
      .catch(error => {
        console.error(error);
      });
  }

  useEffect(
    () => {
      if (state.reportType === 'transfer') {
        if (state.ipsList.length < 1) {
          getAllIps();
        }
        if (state.attentionNetworkList.length < 1) {
          getAttentionNetwork();
        }
        if (state.ambulanceEntityList.length < 1) {
          getAmbulanceEntityList();
        }
        if (state.epsList.length < 1) {
          getAllEps();
        }
      }
      if (state.reportType === 'occupation') {
        if (state.ipsList.length < 1) {
          getAllIps();
        }
      }
    },
    [state.reportType]
  );

  function handleChange(event) {
    setState({
      ...state,
      [event.target.name]: event.target.value
    });
  }

  function handleIpsChange(event) {
    if (event.target.value === 1) {
      setState({
        ...state,
        ipsReceives: undefined,
        ipsRequesting: props.userInfo.attributes.entityId[0],
        ipsForIps: event.target.value
      });
    } else if (event.target.value === 2) {
      setState({
        ...state,
        ipsReceives: props.userInfo.attributes.entityId[0],
        ipsRequesting: undefined,
        ipsForIps: event.target.value
      });
    }
  }

  function finalDateSetting(date) {
    const day = getDate(date);
    const dayAdjusted = day + 1;
    const finalDateAdjusted = setDate(date, dayAdjusted);
    return format(finalDateAdjusted, 'X');
  }

  function handleDateChange(date, name) {
    setState({
      ...state,
      [name]: date
    });
  }

  function handleReportTypeChange(event) {
    const { alert } = props;
    try {
      if (props.roles.includes('IPS')) {
        if (event.target.value === 'occupation') {
          setState({
            ...state,
            ...defaultState,
            ips: props.userInfo.attributes.entityId[0],
            [event.target.name]: event.target.value
          });
          return;
        }
      } else if (props.roles.includes('TRANSPORT')) {
        setState({
          ...state,
          ...defaultState,
          ambulanceEntity: props.userInfo.attributes.entityId[0],
          [event.target.name]: event.target.value
        });
        return;
      } else if (props.roles.includes('EPS')) {
        setState({
          ...state,
          ...defaultState,
          eps: props.userInfo.attributes.entityId[0],
          [event.target.name]: event.target.value
        });
        return;
      }
      setState({
        ...state,
        ...defaultState,
        [event.target.name]: event.target.value
      });
    } catch (error) {
      console.error(error);
      alert.show('Su entidad no está correctamente registrada en nuestro sistema', {
        type: 'error'
      });
    }
  }

  function enableButton() {
    const { initialDate, finalDate, reportType, ipsForIps } = state;
    const { roles } = props;
    if (
      reportType === 'capacity' ||
      reportType === 'covid' ||
      reportType === 'ira' ||
      reportType === 'covid-outcome' ||
      reportType === 'beds-out-of-service' ||
      reportType === 'ira-outcome' ||
      reportType === 'user_safix' ||
      reportType === 'bill_safix'
    ) {
      return false;
    }
    if ((reportType === 'transfer' || reportType === 'occupation') && (initialDate && finalDate)) {
      if (roles.includes('IPS') && !ipsForIps && reportType === 'transfer') {
        return true;
      }
      return false;
    }
    return true;
  }

  function getReport() {
    const { alert } = props;
    setState(prevState => {
      return {
        ...prevState,
        loading: true
      };
    });
    const { reportType } = state;
    let dateString = '';
    let urlString = '';
    let tipoDeReporte = '';
    const initDate = state.initialDate ? format(state.initialDate, 'X') : undefined;
    const finDate = state.finalDate ? finalDateSetting(state.finalDate) : undefined;
    const ipsRequesting = state.ipsRequesting === 'all' ? undefined : state.ipsRequesting;
    const ipsReceives = state.ipsReceives === 'all' ? undefined : state.ipsReceives;
    const triageID = state.triage === 3 ? undefined : state.triage;
    const networkID = state.attentionNetwork === 'all' ? undefined : state.attentionNetwork;
    const ambulancesEntityID = state.ambulanceEntity === 'all' ? undefined : state.ambulanceEntity;
    const ipsID = state.ips === 'all' ? undefined : state.ips;
    const epsID = state.eps === 'all' ? undefined : state.eps;
    const qString = {
      ipsReqId: ipsRequesting,
      ipsRecvId: ipsReceives,
      ipsId: ipsID,
      epsId: epsID,
      initialDate: initDate,
      endDate: finDate,
      ambulancesEntityId: ambulancesEntityID,
      networkId: networkID,
      triage: triageID
    };
    const querystring = queryString.stringify(qString, { arrayFormat: 'index' });
    if (reportType === 'capacity') {
      dateString = '';
      urlString = 'ips/capacities/';
      tipoDeReporte = 'Capacidad';
    } else if (reportType === 'transfer') {
      dateString = `${format(state.initialDate, 'YYYY-MM-DD')} ${format(state.finalDate, 'YYYY-MM-DD')}`;
      urlString = 'referrals?';
      tipoDeReporte = 'Traslados';
    } else if (reportType === 'beds-out-of-service') {
      dateString = `(${format(new Date(), 'YYYY-MM-DD')})`;
      urlString = 'ips/bedsOutOfService';
      tipoDeReporte = 'camas_fuera_de_servicio';
    } else if (reportType === 'occupation') {
      dateString = `${format(state.initialDate, 'YYYY-MM-DD')} ${format(state.finalDate, 'YYYY-MM-DD')}`;
      urlString = 'ips/usageAndAvailability?';
      tipoDeReporte = 'Disponibilidad_y_Ocupacion';
    } else if (reportType === 'covid') {
      dateString = '';
      urlString = 'ips/currentCovidPatients/';
      tipoDeReporte = 'Covid_Actuales';
    } else if (reportType === 'ira') {
      dateString = '';
      urlString = 'ips/currentIRAPatients/';
      tipoDeReporte = 'IRA_Actuales';
    } else if (reportType === 'covid-outcome') {
      dateString = '';
      urlString = 'ips/dischargedCovidPatients/';
      tipoDeReporte = 'Covid_Dados_de_Alta';
    } else if (reportType === 'ira-outcome') {
      dateString = '';
      urlString = 'ips/dischargedIraPatients/';
      tipoDeReporte = 'IRA_Dados_de_Alta';
    } else if (reportType === 'user_safix') {
      dateString = `${format(state.initialDate, 'YYYY-MM-DD')} ${format(state.finalDate, 'YYYY-MM-DD')}`;
      urlString = 'ips/safixUserStructure?';
      tipoDeReporte = 'Usuarios_Safix';
    } else if (reportType === 'bill_safix') {
      dateString = `${format(state.initialDate, 'YYYY-MM-DD')} ${format(state.finalDate, 'YYYY-MM-DD')}`;
      urlString = 'ips/safixBillStructure?';
      tipoDeReporte = 'Factura_Safix';
    }
    apiClient.SEM.get(`${urlApi}/reports/${urlString}${querystring}`, {
      responseType: 'blob',
      timeout: 30000
    })
      .then(response => {
        FileDownload(response.data, `reporte_${tipoDeReporte}${dateString}.xlsx`);
        alert.show('Se está descargando el archivo, espere un momento', {
          type: 'info'
        });
      })
      .catch(error => {
        alert.show(`Se a producido un error al descargar el archivo.`, {
          type: 'error',
          timeout: 5000
        });
        console.error(`Se a producido un error al descargar el archivo. ${error}`);
      })
      .finally(() => {
        setState(prevState => {
          return {
            ...prevState,
            loading: false
          };
        });
      });
    return null;
  }

  function selectReport() {
    switch (state.reportType) {
      case 'annex5':
        return <Annex5Report classes={classes} valid={valid} validFinalDate={validFinalDate} />;
      case 'user_safix':
        return (
          <UserSafixReport
            valid={valid}
            validFinalDate={validOnlyOneMonth}
            handleChange={handleChange}
            handleDateChange={handleDateChange}
            classes={classes}
            initialDate={state.initialDate}
            finalDate={state.finalDate}
          />
        );
      case 'bill_safix':
        return (
          <BillSafixReport
            valid={valid}
            validFinalDate={validOnlyOneMonth}
            handleChange={handleChange}
            handleDateChange={handleDateChange}
            classes={classes}
            initialDate={state.initialDate}
            finalDate={state.finalDate}
          />
        );
      case 'occupation':
        return (
          <OccupationReport
            valid={valid}
            validFinalDate={validFinalDate}
            handleChange={handleChange}
            handleDateChange={handleDateChange}
            classes={classes}
            initialDate={state.initialDate}
            finalDate={state.finalDate}
            ipsList={state.ipsList}
            ips={state.ips}
            roles={props.roles}
            userInfo={props.userInfo}
          />
        );
      case 'transfer':
        return (
          <TransferReport
            initialDate={state.initialDate}
            finalDate={state.finalDate}
            ipsList={state.ipsList}
            ipsReceives={state.ipsReceives}
            ipsRequesting={state.ipsRequesting}
            ipsForIps={state.ipsForIps}
            epsList={state.epsList}
            eps={state.eps}
            triage={state.triage}
            attentionNetwork={state.attentionNetwork}
            ambulanceEntity={state.ambulanceEntity}
            attentionNetworkList={state.attentionNetworkList}
            ambulanceEntityList={state.ambulanceEntityList}
            handleDateChange={handleDateChange}
            roles={props.roles}
            userInfo={props.userInfo}
            valid={valid}
            handleChange={handleChange}
            handleIpsChange={handleIpsChange}
            validFinalDate={validFinalDate}
            classes={classes}
          />
        );
      default:
        return null;
    }
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <NxSpinner loading={state.loading} />
        <Card color="blank">
          <CardHeader>
            <h3 className={classes.cardTitleWhite}>Reportes</h3>
          </CardHeader>
          <CardBody>
            <GridItem container>
              <GridItem xs={12} sm={4}>
                <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                  Tipo de reporte:
                </InputLabel>
                <FormControl fullWidth className={classes.selectStyles} style={{ marginTop: '10px' }}>
                  <Select
                    disableUnderline
                    value={state.reportType || ''}
                    onChange={handleReportTypeChange}
                    fullWidth
                    inputProps={{
                      name: 'reportType',
                      id: 'reportType'
                    }}
                  >
                    <MenuItem value="transfer">Traslado</MenuItem>
                  </Select>
                </FormControl>
              </GridItem>
            </GridItem>
            <div>{selectReport()}</div>
            <GridItem container justify="center">
              {state.reportType !== 'annex5' && (
                <Button
                  className={classes.actionButton}
                  onClick={() => getReport()}
                  style={{ marginTop: '20px' }}
                  disabled={enableButton()}
                >
                  Descargar
                </Button>
              )}
            </GridItem>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
});

ReportsCOT.propTypes = {
  alert: PropTypes.object,
  classes: PropTypes.object,
  roles: PropTypes.array,
  userInfo: PropTypes.object
};

const ReportCOTComponent = withAlert()(withStyles(reportsStyles)(ReportsCOT));

export default props => (
  <Subscribe to={[AuthContainer]}>
    {AuthContainer => (
      <ReportCOTComponent {...props} roles={AuthContainer.state.roles} userInfo={AuthContainer.state.userInfo} />
    )}
  </Subscribe>
);
