import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withAlert } from 'react-alert';
import apiClient from 'config/apiClient';

import Modal from 'nxComponents/Modal/Modal';
import Button from 'components/CustomButtons/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
// import IconButton from '@material-ui/core/IconButton';
// import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Subscribe } from 'unstated';
import AuthContainer from 'containers-state/auth';

import ChatContainer from 'containers-state/chatState';

import { withRouter } from 'react-router-dom';
import { WrapperFloatProvider } from 'context-api/WrapperFloatContext';
import TransferContainer from 'containers-state/transferState';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import GridContainer from 'components/Grid/GridContainer';
import CustomInput from 'components/CustomInput/CustomInput';
import GridItem from 'components/Grid/GridItem';
import Select from '@material-ui/core/Select';
import ImageUpload from 'components/CustomUpload/ImageUpload';
import NxSpinner from '../NxSpinner/NxSpinner';

// import ChatContainer from '../../containers-state/chatState';

import { MapConsumer } from '../../context-api/MapContext';
import { urlApi } from '../../config/app';
import RocketIcon from './RocketIcon';

const MapModal = React.lazy(() => import('../MapContainer/MapModal'));

const styles = theme => ({
  root: {
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  actionsButton: {
    margin: 0,
    background: '#f57a3e',
    padding: '5px',
    height: '35px',
    width: '35px',
    '& .MuiButton-startIcon.MuiButton-iconSizeMedium': {
      margin: 0
    },
    '& svg': {
      transform: 'scale(1.5)',
    }
  }
});

// const options = ['Seleccione una opción', 'Asignar Ambulancia', 'Asignar IPS'];

const crueIpsRejectOptions = [
  {
    value: 'Paciente abandonó servicio',
    id: 'crueIpsRejectOptionsId1'
  },
  {
    value: 'Paciente no acepta traslado',
    id: 'crueIpsRejectOptionsId2'
  },
  {
    value: 'Paciente no aplica para SEM',
    id: 'crueIpsRejectOptionsId3'
  },
  {
    value: 'Traslado primario desde la IPS',
    id: 'crueIpsRejectOptionsId4'
  },
  {
    value: 'EPS sin red en Envigado',
    id: 'crueIpsRejectOptionsId5'
  },
  {
    value: 'Cambio de aseguramiento',
    id: 'crueIpsRejectOptionsId6'
  },
  {
    value: 'Información no corresponde al paciente',
    id: 'crueIpsRejectOptionsId7'
  },
  {
    value: 'Paciente Fallece',
    id: 'crueIpsRejectOptionsId1'
  },
  {
    value: 'Otra',
    id: 'crueIpsRejectOptionsId1'
  }
];

const epsRejectOptions = [
  {
    value: 'No pertinente para SEM',
    id: 'epsRejecOptionId1'
  }
];

class SimpleListMenu extends React.PureComponent {
  state = {
    anchorEl: null,
    selectedIndex: 1,
    loading: false
  };

  isIpsRecipient = id => {
    const { transferData } = this.props;
    return parseInt(id, 10) === (transferData.state.currentTransfer || {}).ipsRecvId;
  };

  isIpsApplicant = id => {
    const { transferData } = this.props;
    return parseInt(id, 10) === (transferData.state.currentTransfer || {}).ipsReqId;
  };

  isTransportEntity = id => {
    const { transferData } = this.props;
    return parseInt(id, 10) === (transferData.state.currentTransfer || {}).ambulancesEntityId;
  };

  getOptions = currentTransfer => {
    // ToDo: se esta renderizando un monton de veces, si se tiene roles, userinfo y currentransfer solo debe llamarse a este metodo una vez
    const { roles, userInfo } = this.props;
    const {
      ambAssignedBy,
      ipsAssignedBy,
      referralState,
      attentionHours,
      ambulancesEntityId,
      allowAllAmbulancesEntities
    } = currentTransfer;
    const activateAmbAssign = !ambAssignedBy && ipsAssignedBy;
    const options = [{ label: 'Seleccione una opción', action: 'none' }];

    if (['EPS', 'EPS_REFERRAL_MANAGER'].some(r => roles.includes(r))) {
      options.push({ label: 'Adjuntar archivo', action: 'uploadFile' });
      if (!ipsAssignedBy && referralState === 'EPS_PENDING_IPS') {
        options.push({ label: 'Asignar IPS', action: 'asgIps' });
      }
      if (activateAmbAssign && !ambulancesEntityId) {
        options.push({ label: 'Asignar entidad de transporte', action: 'asgEntity' });
      }
      if (!attentionHours.transportDate && referralState !== 'REEJECTED') {
        options.push({ label: 'Cancelar traslado', action: 'cancelTransfer' });
      }
      return options;
    }
    if (['CRUE_DEP', 'CRUE_DEP_REFERRAL_MANAGER'].some(r => roles.includes(r))) {
      options.push({ label: 'Adjuntar archivo', action: 'uploadFile' });
      if (!ipsAssignedBy && referralState === 'CRUE_DEP_PENDING_IPS') {
        options.push({ label: 'Asignar IPS', action: 'asgIps' });
      }
      if (activateAmbAssign && !ambulancesEntityId && !allowAllAmbulancesEntities) {
        options.push({
          label: 'Añadir al chat todas las entidades',
          action: 'addAllPrivAmb'
        });
      }
      if (
        activateAmbAssign &&
        !ambulancesEntityId &&
        allowAllAmbulancesEntities &&
        referralState === 'PENDING_AMBULANCE'
      ) {
        options.push({ label: 'Asignar entidad de transporte', action: 'asgEntities' });
      }
      if (!attentionHours.transportDate && referralState !== 'REEJECTED') {
        options.push({ label: 'Cancelar traslado', action: 'cancelTransfer' });
      }
      return options;
    }
    if (['CRUE', 'CRUE_REFERRAL_MANAGER', 'CRUE_RESOURCES_AUDITOR'].some(r => roles.includes(r))) {
      options.push({ label: 'Adjuntar archivo', action: 'uploadFile' });
      if (!ipsAssignedBy && referralState === 'CRUE_PENDING_IPS') {
        options.push({ label: 'Asignar IPS', action: 'asgIps' });
      }
      if (activateAmbAssign) options.push({ label: 'Asignar Ambulancia', action: 'asgAmb' });
      if (
        activateAmbAssign &&
        !ambulancesEntityId &&
        allowAllAmbulancesEntities &&
        referralState === 'PENDING_AMBULANCE'
      ) {
        options.push({ label: 'Asignar entidad de transporte', action: 'asgEntities' });
      }
      if (activateAmbAssign && !ambulancesEntityId && !allowAllAmbulancesEntities) {
        options.push({
          label: 'Añadir al chat todas las entidades',
          action: 'addAllPrivAmb'
        });
      }
      if (!attentionHours.transportDate && referralState !== 'REEJECTED') {
        options.push({ label: 'Cancelar traslado', action: 'cancelTransfer' });
      }
      return options;
    }

    if (['IPS_REFERRAL_REPORTER'].some(r => roles.includes(r))) {
      const confirmReceptionByIps =
        this.isIpsRecipient(userInfo.attributes.entityId) &&
        attentionHours.ambulanceDispatchDate &&
        !attentionHours.patientCareEndDate;
      if (this.isIpsApplicant(userInfo.attributes.entityId)) {
        if (!attentionHours.ambulanceDispatchDate && referralState !== 'REJECTED') {
          options.push({ label: 'Adjuntar archivo', action: 'uploadFile' });
          options.push({ label: 'Cancelar traslado', action: 'cancelTransfer' });
        }
        if (attentionHours.ambulanceDispatchDate && !attentionHours.patientCareEndDate) {
          options.push({ label: 'Confirmar llegada del paciente', action: 'confirmReceptionIpsReq' });
        }
      } else {
        if (confirmReceptionByIps) {
          options.push({ label: 'Confirmar llegada del paciente', action: 'confirmReception' });
        }
        if (!attentionHours.validationCaseDate) {
          options.push({ label: 'Puedo atender el paciente', action: 'iCanTake' });
          options.push({
            label: 'No puedo atender el paciente',
            action: 'iCanNotTake'
          });
        }
      }
      return options;
    }

    if (['TRANSPORT_REFERRAL_MANAGER'].some(r => roles.includes(r))) {
      if (!ambulancesEntityId) {
        options.push({ label: 'Puedo transportar el paciente', action: 'iCanTransport' });
        options.push({ label: 'No puedo transportar el paciente', action: 'iCanNotTransport' });
      }
      if (this.isTransportEntity(userInfo.attributes.entityId)) {
        if (!ambAssignedBy && ipsAssignedBy && referralState !== 'REJECTED') {
          options.push({ label: 'Asignar la ambulancia', action: 'asgPrivateAmb' });
        }
      }
      return options;
    }
    return [{ label: 'No hay opciones habilitadas para usted', action: 'none' }];
  };

  getIPSAgremmentsOptions = () => {
    const { transferData } = this.props;
    return transferData.state.ipsAgreements.map(ips => (
      <MenuItem key={ips.id} value={ips.id}>
        {ips.name}
      </MenuItem>
    ));
  };

  offerAmbulance = ambulanceId => {
    this.setState({ loading: true });
    const { transferData, userInfo, alert } = this.props;
    const { currentTransfer } = transferData.state;
    const { privateAmbulances } = this.state;
    const ambulance = privateAmbulances.find(privAmb => privAmb.id === ambulanceId);
    transferData
      .entitieOfferAmbulance(currentTransfer.id, ambulanceId)
      .then(() => {
        alert.show(
          `La entidad ${userInfo.username} informa que puede trasportar el paciente con la ambulancia de placa ${
            ambulance.licensePlate
          }`,
          {
            type: 'info',
            timeout: 5000
          }
        );
      })
      .finally(() =>
        this.setState({
          loading: false
        })
      );
    this.handleCloseModal();
  };

  renderActionsModal = () => {
    const { transferData, roles } = this.props;
    const {
      ipsRecvId,
      acceptanceCode,
      option,
      rejectedReason,
      otherRejectedReason,
      diagnosisFile,
      ambAcceptanceCodeEps,
      selectedEntity,
      privateAmbulance
    } = this.state;
    const disableAsgAmb = !selectedEntity || !ambAcceptanceCodeEps;
    const disableAsgEnt = !selectedEntity;
    const disableAsgIps = [
      'CRUE',
      'CRUE_REFERRAL_MANAGER',
      'CRUE_RESOURCES_AUDITOR',
      'CRUE_DEP',
      'CRUE_DEP_REFERRAL_MANAGER'
    ].some(r => roles.includes(r))
      ? !ipsRecvId
      : !acceptanceCode || !ipsRecvId;

    return (
      <React.Fragment>
        {option === 'asgIps' && (
          <div>
            {transferData.state.currentTransfer && !transferData.state.currentTransfer.ipsAssigned && (
              <Button color="primary" onClick={this.assignIps} disabled={disableAsgIps}>
                Asignar IPS
              </Button>
            )}
          </div>
        )}
        {option === 'cancelTransfer' && (
          <div>
            {transferData.state.currentTransfer && !transferData.state.currentTransfer.ipsAssigned && (
              <Button
                color="primary"
                onClick={this.cancelTransfer}
                disabled={!rejectedReason || (rejectedReason === 'Otra' && !otherRejectedReason)}
              >
                Cancelar traslado
              </Button>
            )}
          </div>
        )}
        {option === 'addAllPrivAmb' && (
          <div>
            {transferData.state.currentTransfer && (
              <Button color="primary" onClick={this.addAllPrivateAmbulances}>
                Aceptar
              </Button>
            )}
          </div>
        )}
        {option === 'uploadFile' && (
          <div>
            {transferData.state.currentTransfer && (
              <Button
                color="primary"
                onClick={this.uploadDiagnosis}
                disabled={!diagnosisFile || diagnosisFile.type !== 'application/pdf'}
              >
                Adjuntar
              </Button>
            )}
          </div>
        )}
        {option === 'asgEntity' && (
          <div>
            {transferData.state.currentTransfer && (
              <Button color="primary" onClick={this.assignEntity} disabled={disableAsgAmb}>
                Asignar entidad
              </Button>
            )}
          </div>
        )}
        {option === 'asgEntities' && (
          <div>
            {transferData.state.currentTransfer && (
              <Button color="primary" onClick={this.assignEntity} disabled={disableAsgEnt}>
                Asignar entidad
              </Button>
            )}
          </div>
        )}
        {option === 'asgPrivateAmb' && (
          <div>
            {transferData.state.currentTransfer && (
              <Button color="primary" onClick={this.assignPrivateAmb} disabled={!privateAmbulance}>
                Asignar ambulancia
              </Button>
            )}
          </div>
        )}
        {option === 'iCanTransport' && (
          <div>
            {transferData.state.currentTransfer && (
              <Button
                color="primary"
                onClick={() => this.offerAmbulance(privateAmbulance)}
                disabled={!privateAmbulance}
              >
                Ofertar ambulancia
              </Button>
            )}
          </div>
        )}
      </React.Fragment>
    );
  };

  assignIps = () => {
    this.setState({ loading: true });
    const { transferData, alert } = this.props;
    const { ipsRecvId, acceptanceCode } = this.state;
    transferData
      .saveAsignedIPS(ipsRecvId, acceptanceCode)
      .then(response => {
        alert.show(response, {
          type: 'info',
          timeout: 5000
        });
      })
      .catch(error => {
        console.error(error);
        alert.show('Hubo un problema al asignar la IPS', {
          type: 'error',
          timeout: 5000
        });
      })
      .finally(() => this.setState({ loading: false }));
    this.handleCloseModal();
  };

  uploadDiagnosis = () => {
    this.setState({ loading: true });
    const { transferData, alert } = this.props;
    const { diagnosisFile } = this.state;
    transferData
      .uploadDiagnosesFile(diagnosisFile)
      .then(response => {
        alert.show(response, {
          type: 'info',
          timeout: 5000
        });
      })
      .catch(() => {
        alert.show('Hubo un problema al cargar el archivo', {
          type: 'error',
          timeout: 5000
        });
      })
      .finally(() => this.setState({ loading: false }));
    this.handleCloseModal();
  };

  assignEntity = () => {
    this.setState({ loading: true });
    const { transferData, alert } = this.props;
    const { selectedEntity, ambAcceptanceCodeEps } = this.state;
    const referralId = transferData.state.currentTransfer.id;
    const data = {
      entityId: selectedEntity,
      acceptanceCode: ambAcceptanceCodeEps
    };
    transferData
      .assignPrivateEntity(referralId, data)
      .then(() => {
        alert.show('Se ha asignado la entidad correctamente.', {
          type: 'info',
          timeout: 6000
        });
      })
      .catch(error => {
        console.error(error);
        alert.show('Hubo un problema al asignar la entidad.', {
          type: 'error',
          timeout: 6000
        });
      })
      .finally(() => this.setState({ loading: false }));
    this.handleCloseModal();
  };

  assignPrivateAmb = () => {
    this.setState({ loading: true });
    const { transferData, alert } = this.props;
    const { privateAmbulance } = this.state;
    const referralId = transferData.state.currentTransfer.id;
    const data = { ambulanceId: privateAmbulance };
    transferData
      .assignPrivateAmbulance(referralId, data)
      .then(() => {
        alert.show('Se ha asignado la ambulancia correctamente.', {
          type: 'info',
          timeout: 6000
        });
      })
      .catch(() => {
        alert.show('Hubo un problema al asignar la ambulancia.', {
          type: 'error',
          timeout: 6000
        });
      })
      .finally(() => this.setState({ loading: false }));
    this.handleCloseModal();
  };

  cancelTransfer = () => {
    this.setState({ loading: true });
    const { transferData, alert } = this.props;
    const { currentTransfer } = transferData.state;
    const { rejectedReason, otherRejectedReason } = this.state;
    const data = {
      rejectedReason: rejectedReason !== 'Otra' ? rejectedReason : otherRejectedReason
    };
    transferData
      .rejectTrasfer(currentTransfer.id, data)
      .then(() => {
        alert.show('El traslado se ha cancelado', {
          type: 'info',
          timeout: 5000
        });
      })
      .catch(() => {
        alert.show('Hubo un problema al cancelar el traslado', {
          type: 'error',
          timeout: 5000
        });
      })
      .finally(() => this.setState({ loading: false }));
    this.handleCloseModal();
  };

  addAllPrivateAmbulances = () => {
    this.setState({ loading: true });
    const { transferData, alert } = this.props;
    const { currentTransfer } = transferData.state;
    transferData
      .addAllPrivateEntities(currentTransfer.id)
      .then(() => {
        alert.show('Se han añadido todas las entidades privadas', {
          type: 'info',
          timeout: 5000
        });
      })
      .finally(() =>
        this.setState({
          loading: false
        })
      );
    this.handleCloseModal();
  };

  handleClickListItem = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuItemClick = (event, index, option) => {
    const { mapContext, addMessage, transferData, userInfo } = this.props;
    const { currentTransfer } = transferData.state;
    this.setState({ selectedIndex: index, anchorEl: null });
    if (option.action === 'asgAmb') {
      mapContext.openMapModal();
    } else if (option.action === 'asgIps') {
      transferData.getIPSbyNetworkId(currentTransfer.id);
      this.setState({ showModal: true, option: option.action, titleModal: 'Asignar la IPS' });
    } else if (option.action === 'asgEntity') {
      this.getEntities();
      this.setState({ showModal: true, option: option.action, titleModal: 'Asignar entidad de trasporte' });
    } else if (option.action === 'asgEntities') {
      this.getAllEntities();
      this.setState({ showModal: true, option: option.action, titleModal: 'Asignar entidad de trasporte' });
    } else if (option.action === 'addAllPrivAmb') {
      this.setState({
        showModal: true,
        option: option.action,
        titleModal: 'Añadir al chat todas las entidades de ambulancias privadas'
      });
    } else if (option.action === 'asgPrivateAmb') {
      this.getPrivateAmbulances();
      this.setState({ showModal: true, option: option.action, titleModal: 'Asignar ambulancia' });
    } else if (option.action === 'uploadFile') {
      this.setState({ showModal: true, option: option.action, titleModal: 'Cargar archivo' });
    } else if (option.action === 'iCanTake') {
      addMessage(currentTransfer.id, `La IPS ${userInfo.username} informa que puede atender el paciente`, 'ADM');
    } else if (option.action === 'iCanNotTake') {
      addMessage(currentTransfer.id, `La IPS ${userInfo.username} informa que NO puede atender el paciente`, 'ADM');
    } else if (option.action === 'iCanTransport') {
      this.getPrivateAmbulances();
      this.setState({ showModal: true, option: option.action, titleModal: 'Ofertar ambulancia' });
    } else if (option.action === 'iCanNotTransport') {
      addMessage(
        currentTransfer.id,
        `La entidad ${userInfo.username} informa que NO puede transportar el paciente`,
        'ADM'
      );
    } else if (option.action === 'cancelTransfer') {
      this.setState({ showModal: true, option: option.action, titleModal: 'Cancelar el traslado' });
    } else if (option.action === 'confirmReception') {
      addMessage(currentTransfer.id, `La IPS ${userInfo.username} ha confirmado la recepción del paciente`, 'ADM');
      this.assignHour();
    } else if (option.action === 'confirmReceptionIpsReq') {
      addMessage(
        currentTransfer.id,
        `La IPS solicitante(${userInfo.username}) ha confirmado la recepción del paciente`,
        'ADM'
      );
    }
  };

  assignHour = () => {
    const { transferData, alert } = this.props;
    const { currentTransfer } = transferData.state;
    apiClient.SEM.patch(`${urlApi}/referral/${currentTransfer.id}/success`)
      .then(response => {
        transferData.saveHours(response.data.data);
        alert.show('Se ha confirmado la recepción del paciente', {
          type: 'info',
          timeout: 5000
        });
      })
      .catch(() => {
        alert.show('Hubo un error al reportar la recepción', {
          type: 'error',
          timeout: 5000
        });
      });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  uploadHandleChange = file => {
    this.setState({ diagnosisFile: file });
  };

  renderSelectIPS = () => {
    const { classes, transferData, roles } = this.props;
    const { currentTransfer, acceptanceCode } = transferData.state;
    const { ipsRecvId } = this.state;
    return (
      <FormControl fullWidth className={classes.formControl}>
        <InputLabel htmlFor="ipsRecvId">Seleccione la IPS</InputLabel>
        <Select
          value={currentTransfer.ipsRecvId || ipsRecvId || ''}
          onChange={this.handleChange}
          inputProps={{
            name: 'ipsRecvId',
            id: 'ipsRecvId'
          }}
        >
          {this.getIPSAgremmentsOptions()}
          {transferData.state.ipsAgreements.length < 1 ? (
            <MenuItem disabled value="">
              No se encontraron entidades
            </MenuItem>
          ) : null}
        </Select>
        <FormHelperText>Seleccione la IPS de destino para el traslado</FormHelperText>
        {!['CRUE', 'CRUE_REFERRAL_MANAGER', 'CRUE_RESOURCES_AUDITOR', 'CRUE_DEP', 'CRUE_DEP_REFERRAL_MANAGER'].some(r =>
          roles.includes(r)
        ) && (
          <CustomInput
            labelText="Código de aceptación"
            id="acceptanceCode"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: this.handleChange,
              type: 'text',
              name: 'acceptanceCode' || '',
              value: acceptanceCode
            }}
          />
        )}
      </FormControl>
    );
  };

  renderAcceptanceCode = () => {
    const { transferData } = this.props;
    const { acceptanceCode } = transferData.state;

    return (
      <CustomInput
        labelText="Código de aceptación"
        id="acceptanceCode"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          onChange: this.handleChange,
          type: 'text',
          name: 'acceptanceCode' || '',
          value: acceptanceCode
        }}
      />
    );
  };

  getAllEntities = () => {
    apiClient.SEM.get(`${urlApi}/ambulance/entities`)
      .then(({ data }) => {
        this.setState({ entities: data.data });
      })
      .catch(error => {
        console.error(error);
      });
  };

  getEntities = () => {
    apiClient.SEM.get(`${urlApi}/ambulance/entity`)
      .then(({ data }) => {
        this.setState({ entities: data.data });
      })
      .catch(error => {
        console.error(error);
      });
  };

  getPrivateAmbulances = () => {
    apiClient.SEM.get(`${urlApi}/ambulance/entity/all`)
      .then(({ data }) => {
        this.setState({ privateAmbulances: data.data });
      })
      .catch(error => {
        console.error(error);
      });
  };

  renderAsgEntity = () => {
    const { roles } = this.props;
    const { ambAcceptanceCodeEps, selectedEntity, entities = [] } = this.state;
    return (
      <GridItem style={{ width: '350px' }}>
        <FormControl fullWidth>
          <InputLabel htmlFor="rejectedReason">Seleccione la entidad</InputLabel>
          <Select
            value={selectedEntity || ''}
            onChange={this.handleChange}
            inputProps={{
              name: 'selectedEntity',
              id: 'selectedEntity'
            }}
          >
            {entities.map(entity => (
              <MenuItem key={entity.id} value={entity.id}>
                {entity.name}
              </MenuItem>
            ))}
            {entities.length < 1 ? (
              <MenuItem disabled value="">
                No se encontraron opciones
              </MenuItem>
            ) : null}
          </Select>
        </FormControl>
        {!['CRUE', 'CRUE_REFERRAL_MANAGER', 'CRUE_RESOURCES_AUDITOR', 'CRUE_DEP', 'CRUE_DEP_REFERRAL_MANAGER'].some(r =>
        roles.includes(r)) && (
          <CustomInput
            labelText="Código de aceptación"
            id="ambAcceptanceCodeEps"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: this.handleChange,
              type: 'text',
              name: 'ambAcceptanceCodeEps' || '',
              value: ambAcceptanceCodeEps
            }}
          />
        )}
      </GridItem>
    );
  };

  renderPrivareAmbulances = () => {
    const { privateAmbulance, privateAmbulances } = this.state;
    return (
      <GridItem style={{ width: '350px' }}>
        {!privateAmbulances ? (
          <h4 style={{ textAlign: 'center' }}>Cargando ambulancias...</h4>
        ) : (
          <FormControl fullWidth>
            <InputLabel htmlFor="rejectedReason">Seleccione la ambulancia</InputLabel>
            <Select
              value={privateAmbulance || ''}
              onChange={this.handleChange}
              inputProps={{
                name: 'privateAmbulance',
                id: 'privateAmbulance'
              }}
            >
              {privateAmbulances.map(ambulance => (
                <MenuItem key={ambulance.id} value={ambulance.id}>
                  {`${ambulance.name}(${ambulance.licensePlate})`}
                </MenuItem>
              ))}
              {privateAmbulances.length < 1 ? <MenuItem disabled>No se encontraron ambulancias</MenuItem> : null}
            </Select>
          </FormControl>
        )}
      </GridItem>
    );
  };

  renderFileUploader = () => {
    return (
      <ImageUpload
        styles={{ flexBasis: '100px' }}
        title="Buscar diagnóstico"
        onChangeFile={this.uploadHandleChange}
        addButtonProps={{ color: 'info' }}
        changeButtonProps={{ color: 'info' }}
        removeButtonProps={{ color: 'danger' }}
      />
    );
  };

  rejectOptionSelect = () => {
    const { roles } = this.props;
    const options = roles.includes('EPS') ? epsRejectOptions : crueIpsRejectOptions;
    return options.map(option => (
      <MenuItem value={option.value} key={option.id}>
        {option.value}
      </MenuItem>
    ));
  };

  renderReject = () => {
    const { classes } = this.props;
    const { rejectedReason, otherRejectedReason } = this.state;
    return (
      <div>
        <h4>¿Está seguro de cancelar el traslado?</h4>
        <FormControl fullWidth className={classes.formControl}>
          <InputLabel htmlFor="rejectedReason">Seleccione el motivo</InputLabel>
          <Select
            value={rejectedReason || ''}
            onChange={this.handleChange}
            inputProps={{
              name: 'rejectedReason',
              id: 'rejectedReason'
            }}
          >
            {this.rejectOptionSelect()}
          </Select>
          {rejectedReason === 'Otra' && (
            <CustomInput
              labelText="¿Cuál?"
              id="otherRejectedReason"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: this.handleChange,
                type: 'text',
                name: 'otherRejectedReason' || '',
                value: otherRejectedReason
              }}
            />
          )}
        </FormControl>
      </div>
    );
  };

  renderAddAllPrivateAmbulances = () => {
    // const { privateAmbulance, privateAmbulances } = this.state;
    return (
      <div>
        <h4>¿Está seguro que desea agregar todas las entidades de ambulancias al chat?</h4>
      </div>
    );
  };

  getTransferLocatios = type => {
    const { transferData } = this.props;
    let ips;
    if (type === 'origin') {
      ips = transferData.state.currentTransfer ? transferData.state.currentTransfer.ipsReq : {};
    }
    if (type === 'destination') {
      ips = transferData.state.currentTransfer ? transferData.state.currentTransfer.ipsRecv : {};
    }
    return ips;
  };

  render() {
    const { classes, transferData, roles } = this.props;
    const { currentTransfer } = transferData.state;
    const { anchorEl, selectedIndex, showModal, titleModal, option, loading } = this.state;
    const open = Boolean(anchorEl);
    return (
      <div className={classes.root}>
        <NxSpinner loading={loading} />
        {showModal && (
          <Modal
            actionsModal={() => this.renderActionsModal(option)}
            titleModal={titleModal.toUpperCase()}
            handleClose={this.handleCloseModal}
            showModal={showModal}
          >
            {currentTransfer ? (
              <GridContainer>
                <GridItem xs={12}>
                  {option === 'asgIps' && this.renderSelectIPS()}
                  {option === 'asgCod' && this.renderAcceptanceCode()}
                  {option === 'cancelTransfer' && this.renderReject()}
                  {option === 'addAllPrivAmb' && this.renderAddAllPrivateAmbulances()}
                  {option === 'uploadFile' && this.renderFileUploader()}
                  {option === 'asgEntity' && this.renderAsgEntity()}
                  {option === 'asgEntities' && this.renderAsgEntity()}
                  {option === 'asgPrivateAmb' && this.renderPrivareAmbulances()}
                  {option === 'iCanTransport' && this.renderPrivareAmbulances()}
                </GridItem>
              </GridContainer>
            ) : (
              <h1>Cargando....</h1>
            )}
          </Modal>
        )}
        <Button className={classes.actionsButton} size="sm" onClick={this.handleClickListItem} startIcon={<RocketIcon />} />
        <Menu id="lock-menu" anchorEl={anchorEl} open={open} onClose={this.handleClose}>
          {currentTransfer &&
            currentTransfer.attentionHours &&
            this.getOptions(currentTransfer).map((optionLoc, index) => (
              <MenuItem
                key={index}
                disabled={optionLoc.action === 'none'}
                selected={index === selectedIndex}
                onClick={event => this.handleMenuItemClick(event, index, optionLoc)}
                style={optionLoc.action === 'cancelTransfer' ? { color: '#e45a5a' } : null}
              >
                {optionLoc.label}
              </MenuItem>
            ))}
        </Menu>
        <WrapperFloatProvider>
          {['CRUE', 'CRUE_DEP'].some(r => roles.includes(r)) && (
            <React.Suspense fallback={<div>Loading...</div>}>
              <MapModal
                originIps={this.getTransferLocatios('origin')}
                destinationIps={this.getTransferLocatios('destination')}
              />
            </React.Suspense>
          )}
        </WrapperFloatProvider>
      </div>
    );
  }
}

SimpleListMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  roles: PropTypes.array,
  mapContext: PropTypes.object.isRequired,
  transferData: PropTypes.object,
  addMessage: PropTypes.func,
  alert: PropTypes.object,
  userInfo: PropTypes.object
};

const SimpleListMenuStyles = withRouter(withAlert()(withStyles(styles)(SimpleListMenu)));

export default props => (
  <Subscribe to={[AuthContainer, TransferContainer, ChatContainer]}>
    {(
      { state },
      {
        state: transferState,
        saveAsignedIPS,
        rejectTrasfer,
        uploadDiagnosesFile,
        assignAmbulance,
        assignPrivateEntity,
        assignPrivateAmbulance,
        addAllPrivateEntities,
        entitieOfferAmbulance,
        getIPSbyNetworkId,
        saveHours
      },
      { addMessage }
    ) => (
      <MapConsumer>
        {({ openMapModal }) => (
          <SimpleListMenuStyles
            {...props}
            roles={state.roles}
            userInfo={state.userInfo}
            mapContext={{ openMapModal }}
            addMessage={addMessage}
            transferData={{
              state: transferState,
              saveAsignedIPS,
              rejectTrasfer,
              uploadDiagnosesFile,
              assignAmbulance,
              assignPrivateEntity,
              assignPrivateAmbulance,
              addAllPrivateEntities,
              entitieOfferAmbulance,
              getIPSbyNetworkId,
              saveHours
            }}
          />
        )}
      </MapConsumer>
    )}
  </Subscribe>
);
