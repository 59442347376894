import React from 'react';
import axios from 'axios';
import intersection from 'lodash/intersection';
import apiClient from 'config/apiClient';
import PropTypes from 'prop-types';
import { withAlert } from 'react-alert';
import { getSolicitudesAmb, updateChangedRequest } from '../models/ambMed';
import { getDistance } from '../utils/GoogleMaps';

// Others
import { UbidotsSocket } from '../containers-state/auth';
import { urlApi, urlUbidots } from '../config/app';

const defaultState = {
  center: {
    lat: 6.16591,
    lng: -75.58174
  },
  showTraffic: false,
  showHeatLayer: false,
  showAmb: false,
  showIps: false,
  showCommunes: false,
  isExcluding: true,
  zoom: 15,
  isMapModalOpen: false
};
const MapContext = React.createContext(defaultState);

// const UBIDOTS_URL = 'https://aphmeddev.iot.ubidots.com/api/v1.6/datasources'; // URL ubidots

class MapProviderContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      ...defaultState,
      reqAmbulances: [],
      selectedReqAmbulances: [],
      reqSelect: { value: '5', label: 'Pendientes' }
    };
  }

  getCommunes = () => {
    apiClient.SEM.get(`${urlApi}/commune/delimitation`).then(({ data }) => this.setState({ communes: data.data }));
  };

  getEntities = () => {
    apiClient.SEM.get(`${urlApi}/ambulance/entities`)
      .then(({ data }) => {
        this.setState({
          entities: data.data
        });
      })
      .catch(error => {
        console.error(error);
      });
  };

  getHospitals = () => {
    apiClient.SEM.get(`${urlApi}/ips`)
      .then(({ data }) => {
        this.setState({
          hospitals: data.data.rows,
          visibleHospitals: data.data.rows
        });
      })
      .catch(error => {
        console.error(error);
      });
  };

  getAmbulances = () =>
    new Promise((resolve, reject) => {
      apiClient.SEM.get(`${urlApi}/ambulance`)
        .then(async ({ data }) => {
          await this.getAmbulancesLocation(data.data);
          resolve(data.data);
        })
        .catch(error => {
          reject();
          console.error(error);
        });
    });

  getPrivateAmbulances = () => {
    apiClient.SEM.get(`${urlApi}/ambulance/private`)
      .then(({ data }) => {
        this.setState({
          privateAmbulances: data.data
        });
      })
      .catch(error => {
        console.error(error);
      });
  };

  getIpsWihoutReports = type => {
    const { hospitals } = this.state;
    if (!type) {
      this.setState({ visibleHospitals: hospitals, reportType: type });
    } else {
      const filterHospitals = [];
      apiClient.SEM.get(`${urlApi}/ips/resources/notReported`)
        .then(({ data }) => {
          const ips = data.data[type.value];
          hospitals.forEach(hospital => {
            if (ips.includes(hospital.id)) {
              filterHospitals.push(hospital);
            }
          });
          this.setState({ visibleHospitals: filterHospitals, reportType: type });
        })
        .catch(error => {
          console.error(error);
        });
    }
  };

  async getAmbulancesLocation(ambulances) {
    const { communes } = this.state;
    if (!communes) {
      this.getCommunes();
    }
    const ambulancesInfo = [];
    const correctAmbulances = ambulances.filter(ambulance => !!ambulance.datasourceId && !ambulance.name.match(/Moto/));
    await Promise.all(this.ambulancesPromises(correctAmbulances).map(this.reflect)).then(response => {
      response.forEach(({ values, status }) => {
        if (status === 'success') {
          const latitude = values.data.results.find(responseKey => responseKey.name === 'lat');
          const longitude = values.data.results.find(responseKey => responseKey.name === 'lon');
          const onService = values.data.results.find(responseKey => responseKey.name === 'On Service');
          const ambulanceInfo = correctAmbulances.find(ambulance => ambulance.datasourceId === latitude.datasource.id);
          ambulanceInfo.location = {
            lat: latitude.last_value.value,
            latId: latitude.id,
            lng: longitude.last_value.value,
            lngId: longitude.id
          };
          ambulanceInfo.onServiceId = onService.id;
          ambulancesInfo.push(ambulanceInfo);
          if (latitude && longitude) {
            this.subscribe(latitude.id, 'latitude', ambulanceInfo);
            this.subscribe(longitude.id, 'longitude', ambulanceInfo);
            this.subscribe(onService.id, 'onService', ambulanceInfo);
          }
        }
      });
    });
    this.setState({
      ambulances: ambulancesInfo,
      visibleAmbulances: ambulancesInfo
    });
  }

  showPrivateAmbulances = async () => {
    this.setState({ loading: true });
    const { ambulances, privateAmbulances = [], selectedEntity = {}, entities, visibleAmbulances } = this.state;
    const ambulancesInfo = [];
    const allAmbulances = [...privateAmbulances, ...ambulances];
    this.unsubscribeAmbulances(visibleAmbulances);
    const entity = selectedEntity || entities.find(privEntity => privEntity.name === 'SECRETARIA DE SALUD DE MEDELLIN');
    const correctAmbulances = allAmbulances.filter(
      ambulance => ambulance.ambulancesEntityId === (entity.value || entity.id)
    );
    await Promise.all(this.ambulancesPromises(correctAmbulances).map(this.reflect)).then(response => {
      response.forEach(({ values, status }) => {
        if (status === 'success') {
          const latitude = values.data.results.find(responseKey => responseKey.name === 'lat');
          const longitude = values.data.results.find(responseKey => responseKey.name === 'lon');
          const onService = values.data.results.find(responseKey => responseKey.name === 'On Service');
          const ambulanceInfo = correctAmbulances.find(ambulance => ambulance.datasourceId === latitude.datasource.id);
          ambulanceInfo.location = {
            lat: latitude.last_value.value,
            latId: latitude.id,
            lng: longitude.last_value.value,
            lngId: longitude.id
          };
          ambulanceInfo.onServiceId = onService.id;
          ambulancesInfo.push(ambulanceInfo);
          if (latitude && longitude) {
            this.subscribe(latitude.id, 'latitude', ambulanceInfo);
            this.subscribe(longitude.id, 'longitude', ambulanceInfo);
            this.subscribe(onService.id, 'onService', ambulanceInfo);
          }
        }
      });
    });
    this.setState({
      visibleAmbulances: ambulancesInfo,
      loading: false
    });
  };

  reflect = p => p.then(values => ({ values, status: 'success' }), error => ({ error, status: 'rejected' }));

  ambulancesPromises = ambulances =>
    ambulances.map(ambulance =>
      axios.get(`${urlUbidots}/${ambulance.datasourceId}/variables`, {
        headers: { 'X-Auth-Token': process.env.REACT_APP_UBI_TOKEN }
      })
    );

  ambulancePromise = async ambulance => {
    const { alert } = this.props;
    this.setState({ loading: true });
    const ambulancesInfo = [];
    const { selectedAmbulance, selectedHospital } = this.state;
    await axios
      .get(`${urlUbidots}/${ambulance.datasourceId}/variables`, {
        headers: { 'X-Auth-Token': process.env.REACT_APP_UBI_TOKEN }
      })
      .then(response => {
        if (response.status === 200) {
          const latitude = response.data.results.find(responseKey => responseKey.name === 'lat');
          const longitude = response.data.results.find(responseKey => responseKey.name === 'lon');
          const onService = response.data.results.find(responseKey => responseKey.name === 'On Service');
          selectedAmbulance.location = {
            lat: latitude.last_value.value,
            latId: latitude.id,
            lng: longitude.last_value.value,
            lngId: longitude.id
          };
          selectedAmbulance.onServiceId = onService.id;
          ambulancesInfo.push(selectedAmbulance);
          if (latitude && longitude) {
            this.subscribe(latitude.id, 'latitude', selectedAmbulance);
            this.subscribe(longitude.id, 'longitude', selectedAmbulance);
            this.subscribe(onService.id, 'onService', selectedAmbulance);
          }
        }
      })
      .then(() => {
        this.setState(
          {
            visibleAmbulances: ambulancesInfo,
            selectedAmbulance: ambulancesInfo,
            showAmb: true,
            showIps: true,
            loading: false
          },
          () => {
            if (selectedAmbulance.location.lat && selectedAmbulance.location.lng) {
              this.transferRoute2(selectedAmbulance, selectedHospital);
            } else {
              alert.show(`La ambulancia ${selectedAmbulance.licensePlate} no tiene activada la geolocalización`, {
                type: 'error',
                timeout: 10000
              });
            }
          }
        );
      });
  };

  subscribe = (variableId, variableName, ambulance) => {
    // Listen for a variable
    UbidotsSocket().emit('rt/variables/id/last_value', {
      variable: variableId
    });
    UbidotsSocket().on(`rt/variables/${variableId}/last_value`, e => {
      this.handleSocketChange(e, variableName, ambulance);
    });
  };

  unsubscribe = (variableId, variableName, ambulance) => {
    UbidotsSocket().emit('unsub/rt/variables/id/last_value', {
      variable: variableId
    });
    UbidotsSocket().off(`rt/variables/${variableId}/last_value`, e => {
      this.handleSocketChange(e, variableName, ambulance);
    });
  };

  handleSocketChange = (data, variableName, ambulanceInfo) => {
    const { visibleAmbulances } = this.state;
    if (visibleAmbulances) {
      const parsedData = JSON.parse(data);
      const index = visibleAmbulances.findIndex(ambulance => ambulance.id === ambulanceInfo.id);
      switch (variableName) {
        case 'latitude':
          ambulanceInfo.location.lat = parsedData.value;
          break;
        case 'longitude':
          ambulanceInfo.location.lng = parsedData.value;
          break;
        case 'onService':
          ambulanceInfo.onService = parsedData.value;
          break;
        default:
          break;
      }
      //  Se agrega el timestamp de la variable para que se pueda poner en el key de marker(NxMap) y react lo identifique como algo nuevo y lo renderice correctamente
      ambulanceInfo.timestamp = parsedData.timestamp;
      const newAmbulances = [...visibleAmbulances];
      newAmbulances[index] = ambulanceInfo;

      this.setState({ visibleAmbulances: newAmbulances }, () => {
        this.filterAmbulances();
      });
    }
  };

  distanceToTransfer = ips => {
    const { visibleAmbulances } = this.state;
    const ambulancesLocation = [];
    const ipsLocation = `${ips.lat},${ips.lng}`;
    if (visibleAmbulances) {
      visibleAmbulances.map(
        ({ location }) => location.lat && location.lng && ambulancesLocation.push(`${location.lat}, ${location.lng}`)
      );
    }
    const service = new window.google.maps.DistanceMatrixService();
    service.getDistanceMatrix(
      {
        origins: [ipsLocation],
        destinations: ambulancesLocation,
        travelMode: 'DRIVING'
      },
      response => {
        const differences = response.rows[0].elements;
        differences.forEach((difference, i) => {
          if (Object.keys(difference).includes('duration')) {
            visibleAmbulances[i].distance = difference.distance.value;
            visibleAmbulances[i].time = difference.duration.value;
          }
        });
        visibleAmbulances.sort((a, b) => a.distance - b.distance);
        this.setState({ visibleAmbulances });
      }
    );
  };

  getDistances = accident => {
    this.setState({
      accident,
      selectedAmbulance: null,
      center: {
        lat: accident.lat(),
        lng: accident.lng()
      }
    });
    const { visibleAmbulances } = this.state;
    const ambulancesLocation = [];
    const accidentLocation = `${accident.lat()},${accident.lng()}`;
    if (visibleAmbulances) {
      visibleAmbulances.map(({ location }) => ambulancesLocation.push(`${location.lat}, ${location.lng}`));
    }
    const service = new window.google.maps.DistanceMatrixService();
    service.getDistanceMatrix(
      {
        origins: [accidentLocation],
        destinations: ambulancesLocation,
        travelMode: 'DRIVING'
      },
      response => {
        const differences = response.rows[0].elements;
        differences.forEach((difference, i) => {
          visibleAmbulances[i].distance = difference.distance ? difference.distance.value : undefined;
          visibleAmbulances[i].time = difference.distance ? difference.duration.value : undefined;
        });
        visibleAmbulances.sort((a, b) => a.distance - b.distance);
        this.setState({ visibleAmbulances });
      }
    );
    this.getHospitalsDistance(accident);
  };

  getHospitalsDistance = accident => {
    const { visibleHospitals } = this.state;
    const validHospitals = [];
    visibleHospitals.forEach(hospital => {
      if (hospital.lat && hospital.lng) {
        hospital.distance = parseFloat(
          getDistance(accident.lat(), accident.lng(), parseFloat(hospital.lat), parseFloat(hospital.lng))
        );
        const time = hospital.distance / ((35 * 1000) / 3600); // assuming speed for 35 km/h for ambulances
        hospital.time = Math.round(time * 100) / 100; // rounded to 2 decimals
        validHospitals.push(hospital);
      }
    });
    validHospitals.sort((a, b) => a.distance - b.distance);
    this.setState({ visibleHospitals: validHospitals });
  };

  toggleMapElement = type => {
    switch (type) {
      case 'amb':
        this.setState(prevState => {
          return { showAmb: !prevState.showAmb };
        });
        break;

      case 'ips':
        this.setState(prevState => {
          return { showIps: !prevState.showIps };
        });
        break;
      case 'trafficLayer':
        this.setState(prevState => {
          return { showTraffic: !prevState.showTraffic };
        });
        break;
      default:
        // la capa de mapa de calor
        this.setState(prevState => {
          return { showHeatLayer: !prevState.showHeatLayer };
        });
        break;
    }
  };

  handleCommuneLayer = () => {
    const { showCommunes } = this.state;
    this.setState({ showCommunes: !showCommunes });
  };

  handleExcludingToggle = () => {
    const { isExcluding } = this.state;
    this.setState({ isExcluding: !isExcluding }, () => this.filterHospitals());
  };

  openMapModal = () => {
    this.setState({
      isMapModalOpen: true
    });
  };

  closeMapModal = () => {
    this.setState({
      isMapModalOpen: false
    });
  };

  handleAmbulanceChange = value => {
    this.setState({
      selectedAmbulance: value
    });
  };

  handleHospitalChange = value => {
    this.setState({
      selectedHospital: value
    });
  };

  handleAmbulanceFilter = value => {
    this.setState({ ambulanceFilter: value }, () => this.filterAmbulances());
  };

  handleEntityFilter = value => {
    this.setState(
      {
        selectedEntity: value,
        center: {
          lat: 6.235925,
          lng: -75.57513
        },
        zoom: 13,
        ambulanceToSearch: null,
        ambulanceFilter: null
      },
      () => this.showPrivateAmbulances()
    );
  };

  handleSearhAmbulance = ambulanceSelected => {
    if (ambulanceSelected) {
      const { visibleAmbulances } = this.state;
      const result = visibleAmbulances.find(ambulance => ambulance.name === ambulanceSelected.value.name);
      const center = {
        lat: result.location.lat,
        lng: result.location.lng
      };
      this.setState({
        center,
        zoom: 15,
        ambulanceToSearch: ambulanceSelected
      });
    } else {
      this.setState({
        ambulanceToSearch: ambulanceSelected,
        center: {
          lat: 6.235925,
          lng: -75.57513
        },
        zoom: 13
      });
    }
  };

  filterAmbulances = () => {
    const { ambulanceFilter, ambulances = [], privateAmbulances = [], selectedEntity } = this.state;
    // Select SSM as default entity
    const entityId = (selectedEntity || {}).value || 1;
    const allAmbulances = [...privateAmbulances, ...ambulances];
    let ambulanceToShow = [];
    ambulanceToShow = allAmbulances.filter(ambulance => ambulance.ambulancesEntityId === entityId);
    if (!ambulanceFilter) {
      ambulanceToShow = selectedEntity === 1 ? ambulances : ambulanceToShow;
    } else {
      ambulanceToShow = ambulanceToShow.filter(ambulance => ambulance.onService === ambulanceFilter.value);
    }
    this.setState({ visibleAmbulances: ambulanceToShow, selectedAmbulance: null });
  };

  // filterAmbulances = () => {
  //   const { ambulanceFilter, ambulances } = this.state;
  //   let visibleAmbulances = [];
  //   if (!ambulanceFilter) {
  //     visibleAmbulances = ambulances;
  //   } else {
  //     visibleAmbulances = ambulances.filter(ambulance => ambulance.onService === ambulanceFilter.value);
  //   }
  //   this.setState({ visibleAmbulances, selectedAmbulance: null });
  // };

  filterHospitals = () => {
    const { isExcluding, networks, hospitals } = this.state;
    if (!networks) return;
    const hospitalNetworks = networks.map(network => network.value);
    const visibleHospitals = hospitals.filter(hospital => {
      let materno = true;
      let trauma = true;
      let acv = true;
      hospitalNetworks.map(hostpitalNetwork => {
        if (hostpitalNetwork === 'RedMaterno' && hospital.network && !hospital.network[hostpitalNetwork]) {
          materno = false;
        } else if (hostpitalNetwork === 'RedTrauma' && hospital.network && !hospital.network[hostpitalNetwork]) {
          trauma = false;
        } else if (hostpitalNetwork === 'RedACV' && hospital.network && !hospital.network[hostpitalNetwork]) {
          acv = false;
        }
        return null;
      });
      let filter;
      if (isExcluding) {
        filter = materno && trauma && acv;
      } else {
        filter = materno || trauma || acv;
      }
      return filter;
    });
    visibleHospitals.sort((a, b) => a.distance - b.distance);
    this.setState({ visibleHospitals });
  };

  getIpsByNetwork = async network => {
    const response = await apiClient.SEM.get(`${urlApi}/agreement/ssm/network/${network.value}`);
    return response.data.data;
  };

  handleNetworkChange = options => {
    const { hospitals, ipsIdByNetwork = {} } = this.state;
    const ipsIds = { ...ipsIdByNetwork };
    let hospitalIds = hospitals.map(h => h.id);
    const visibleHospitals = [];
    const visibleHospitalsAsync = [];
    if (options) {
      options.forEach(option => {
        if (!ipsIds[option.name]) {
          // if (option.name !== 'iam') {
          this.getIpsByNetwork(option).then(res => {
            ipsIds[option.name] = res;
            hospitalIds = intersection(hospitalIds, ipsIds[option.name]);
            hospitals.forEach(hospital => {
              if (hospitalIds.includes(hospital.id)) {
                visibleHospitalsAsync.push(hospital);
              }
            });
            this.setState({ visibleHospitals: visibleHospitalsAsync, ipsIdByNetwork: ipsIds, networks: options });
          });
        } else {
          hospitalIds = intersection(hospitalIds, ipsIds[option.name]);
        }
      });
      hospitals.forEach(hospital => {
        if (hospitalIds.includes(hospital.id)) {
          visibleHospitals.push(hospital);
        }
      });
      this.setState({ visibleHospitals, ipsIdByNetwork: ipsIds, networks: options });
    }

    if (options === null) {
      this.setState({ visibleHospitals: hospitals, networks: options });
    }
  };

  handleReqAmbulanceChange = selected => {
    const { reqAmbulances } = this.state;
    let selectedReq = [];
    if (selected) {
      if (selected.name === 'pending') {
        selectedReq = reqAmbulances.filter(req => !req.status);
      } else if (['attend', 'delete', 'duplicate'].includes(selected.name)) {
        selectedReq = reqAmbulances.filter(req => req.status === selected.name);
      }
      this.setState({ selectedReqAmbulances: selectedReq, reqSelect: selected });
    } else {
      this.setState({ selectedReqAmbulances: reqAmbulances, reqSelect: selected });
    }
  };

  transferRoute = (originIps, destinationIps) => {
    const DirectionsService = new window.google.maps.DirectionsService();
    DirectionsService.route(
      {
        origin: new window.google.maps.LatLng(originIps.lat, originIps.lng),
        destination: new window.google.maps.LatLng(destinationIps.lat, destinationIps.lng),
        travelMode: window.google.maps.TravelMode.DRIVING
      },
      (result, status) => {
        if (status === 'OK') {
          this.setState({ route: result });
        }
      }
    );
  };

  transferRoute2 = (origin, destination) => {
    const DirectionsService = new window.google.maps.DirectionsService();
    DirectionsService.route(
      {
        origin: new window.google.maps.LatLng(origin.location.lat, origin.location.lng),
        destination: new window.google.maps.LatLng(destination.lat, destination.lng),
        travelMode: window.google.maps.TravelMode.DRIVING
      },
      (result, status) => {
        if (status === 'OK') {
          this.setState({ route: result });
        }
      }
    );
  };

  traceRouteAmbToIps = (ambulanceId, ipsId) => {
    const { ambulances, hospitals, privateAmbulances } = this.state;
    const allAmbulances = [...ambulances, ...privateAmbulances];
    const selectedHospital = hospitals.find(hospital => hospital.id === parseInt(ipsId, 10));
    const selectedAmbulance = allAmbulances.find(ambulance => ambulance.id === parseInt(ambulanceId, 10));
    if (selectedHospital && selectedAmbulance) {
      this.setState(
        {
          selectedHospital,
          selectedAmbulance,
          visibleAmbulances: [selectedAmbulance],
          visibleHospitals: [selectedHospital]
        },
        () => {
          this.ambulancePromise(selectedAmbulance);
        }
      );
    }
  };

  traceRoute = () => {
    const { accident, selectedAmbulance: ambulance, selectedHospital: hospital } = this.state;
    const DirectionsService = new window.google.maps.DirectionsService();
    DirectionsService.route(
      {
        origin: `${ambulance.value.location.lat},${ambulance.value.location.lng}`,
        destination: new window.google.maps.LatLng(hospital.value.lat, hospital.value.lng),
        waypoints: [
          {
            location: `${accident.lat()},${accident.lng()}`,
            stopover: true
          }
        ],
        travelMode: window.google.maps.TravelMode.DRIVING
      },
      (result, status) => {
        if (status === 'OK') {
          this.setState({ route: result });
        }
      }
    );
  };

  removeRoute = () => {
    this.setState({
      accident: undefined,
      route: undefined
    });
  };

  resetMapContext = () => {
    const { ambulances, hospitals } = this.state;
    this.setState({
      ...defaultState,
      visibleAmbulances: ambulances,
      route: undefined,
      visibleHospitals: hospitals
    });
  };

  unsubscribeUbidotsSocket = () => {
    const { ambulances } = this.state;
    if (ambulances) {
      ambulances.map(ambulance => {
        this.unsubscribe(ambulance.location.latId, 'latitude', ambulance.id);
        this.unsubscribe(ambulance.location.lngId, 'longitude', ambulance.id);
        this.unsubscribe(ambulance.onServiceId, 'onService', ambulance.id);
        return null;
      });
    }
  };

  unsubscribeAmbulances = ambulances => {
    if (ambulances) {
      ambulances.map(ambulance => {
        this.unsubscribe(ambulance.location.latId, 'latitude', ambulance.id);
        this.unsubscribe(ambulance.location.lngId, 'longitude', ambulance.id);
        this.unsubscribe(ambulance.onServiceId, 'onService', ambulance.id);
        return null;
      });
    }
  };

  addReqAmbulances = newRequest => {
    const { reqAmbulances } = this.state;
    if (!reqAmbulances.find(item => item.id === newRequest.id)) {
      // const { alert } = this.props;
      // alert.show('Ha recibido una nueva solicitud de Ambulancia');
      this.setState(prevState => {
        return { reqAmbulances: prevState.reqAmbulances.concat([newRequest]) };
      });
      this.handleReqAmbulanceChange({ value: '5', label: 'Pendientes', name: 'pending' });
    }
  };

  updateChangedRequest = changedRequest => {
    const { reqAmbulances } = this.state;
    const amb = reqAmbulances.find(item => item.id === changedRequest.id);
    if (amb) {
      const { alert } = this.props;
      alert.show('Se ha actualizado la informacion de la solicitud de ambulancia');
      const indexAmb = reqAmbulances.findIndex(req => req.id === changedRequest.id);
      reqAmbulances[indexAmb] = changedRequest;
      this.setState(() => {
        return { reqAmbulances };
      });
      this.handleReqAmbulanceChange({ value: '5', label: 'Pendientes', name: 'pending' });
    }
  };

  componentDidMount = () => {
    getSolicitudesAmb(this.addReqAmbulances).then(allRequest => {
      this.setState({ reqAmbulances: allRequest, selectedReqAmbulances: allRequest });
      this.handleReqAmbulanceChange({ value: '5', label: 'Pendientes', name: 'pending' });
    });
    updateChangedRequest(this.updateChangedRequest);
  };

  render() {
    const { children } = this.props;
    return (
      <MapContext.Provider
        value={{
          ...this.state,
          distanceToTransfer: this.distanceToTransfer,
          getHospitals: this.getHospitals,
          getAmbulances: this.getAmbulances,
          getEntities: this.getEntities,
          getIpsWihoutReports: this.getIpsWihoutReports,
          getPrivateAmbulances: this.getPrivateAmbulances,
          getDistances: this.getDistances,
          handleAmbulanceChange: this.handleAmbulanceChange,
          handleAmbulanceFilter: this.handleAmbulanceFilter,
          handleEntityFilter: this.handleEntityFilter,
          handleExcludingToggle: this.handleExcludingToggle,
          handleHospitalChange: this.handleHospitalChange,
          handleNetworkChange: this.handleNetworkChange,
          handleReqAmbulanceChange: this.handleReqAmbulanceChange,
          handleSearhAmbulance: this.handleSearhAmbulance,
          toggleMapElement: this.toggleMapElement,
          handleCommuneLayer: this.handleCommuneLayer,
          removeRoute: this.removeRoute,
          traceRoute: this.traceRoute,
          traceRouteAmbToIps: this.traceRouteAmbToIps,
          transferRoute: this.transferRoute,
          openMapModal: this.openMapModal,
          closeMapModal: this.closeMapModal,
          unsubscribeUbidotsSocket: this.unsubscribeUbidotsSocket,
          resetMapContext: this.resetMapContext
        }}
      >
        {children}
      </MapContext.Provider>
    );
  }
}

const MapProvider = withAlert()(MapProviderContainer);

MapProviderContainer.propTypes = {
  children: PropTypes.element.isRequired,
  alert: PropTypes.object
};

export const MapConsumer = MapContext.Consumer;

export default MapProvider;
