// import firebase from 'firebase/app';

import { messaging } from 'config/firebase';
// export const initializeFirebase = () => {

//   firebase.initializeApp(
//     {
//       apiKey: 'AIzaSyDekzZ9YxRTwU5xYgZCXL2kTDpWIIQfstc',
//       // authDomain: "push-notification-232118.firebaseapp.com",
//       // databaseURL: "https://push-notification-232118.firebaseio.com",
//       projectId: 'sem-core',
//       // storageBucket: "push-notification-232118.appspot.com",
//       messagingSenderId: '751696161757'
//     },
//     'push'
//   );

//   // use other service worker
//   // navigator.serviceWorker
//   //   .register('/my-sw.js')
//   //   .then((registration) => {
//   //     firebase.messaging().useServiceWorker(registration);
//   //   });

//   if (firebase.messaging.isSupported()) {
//     messaging = firebase.messaging();
//     messaging.usePublicVapidKey(
//       'BNeUn4WnucYFi38Pg_0qmP9Hnr6q944yEJmL4ftD5a6R_pHrzFmtrXfTtP_ivbbcak_6Csdbujww1HH3p-ZTgck'
//     );
//   }
// };

export const askForPermissioToReceiveNotifications = async () => {
  if (!messaging) return;
  // messaging.usePublicVapidKey(
  //   'BNeUn4WnucYFi38Pg_0qmP9Hnr6q944yEJmL4ftD5a6R_pHrzFmtrXfTtP_ivbbcak_6Csdbujww1HH3p-ZTgck'
  // );

  // [START refresh_token]
  // Callback fired if Instance ID token is updated.
  await messaging;
  messaging.onTokenRefresh(() => {
    messaging
      .getToken()
      .then(refreshedToken => {
        console.log('Token refreshed.', refreshedToken);
        // Indicate that the new Instance ID token has not yet been sent to the
        // app server.
        // setTokenSentToServer(false);
        // Send Instance ID token to app server.
        // sendTokenToServer(refreshedToken);
        // [START_EXCLUDE]
        // Display new Instance ID token and clear UI of all previous messages.
        // resetUI();
        // [END_EXCLUDE]
      })
      .catch(err => {
        console.error('Unable to retrieve refreshed token ', err);
        // showToken('Unable to retrieve refreshed token ', err);
      });
  });
  // [END refresh_token]

  // [START receive_message]
  // Handle incoming messages. Called when:
  // - a message is received while the app has focus
  // - the user clicks on an app notification created by a service worker
  //   `messaging.setBackgroundMessageHandler` handler.
  messaging.onMessage(payload => {
    // [START_EXCLUDE]
    // Update the UI to include the received message.
    // appendMessage(payload);
    // [END_EXCLUDE]
  });
  // [END receive_message]
  try {
    await messaging.requestPermission();
    // TODO(developer): Retrieve an Instance ID token for use with FCM.
    // ...
    const token = await messaging.getToken();

    console.log('token de usuario FCM:', token);

    return token;
  } catch (error) {
    console.log('Unable to get permission to notify.', error);
    throw error;
  }
};

// Get Instance ID token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.
// messaging
//   .getToken()
//   .then(function(currentToken) {
//     if (currentToken) {
//       sendTokenToServer(currentToken);
//       updateUIForPushEnabled(currentToken);
//     } else {
//       // Show permission request.
//       console.log('No Instance ID token available. Request permission to generate one.');
//       // Show permission UI.
//       updateUIForPushPermissionRequired();
//       setTokenSentToServer(false);
//     }
//   })
//   .catch(function(err) {
//     console.log('An error occurred while retrieving token. ', err);
//     showToken('Error retrieving Instance ID token. ', err);
//     setTokenSentToServer(false);
//   });

// function resetUI() {
//   clearMessages();
//   showToken('loading...');
//   // [START get_token]
//   // Get Instance ID token. Initially this makes a network call, once retrieved
//   // subsequent calls to getToken will return from cache.
//   messaging
//     .getToken()
//     .then(function(currentToken) {
//       if (currentToken) {
//         sendTokenToServer(currentToken);
//         updateUIForPushEnabled(currentToken);
//       } else {
//         // Show permission request.
//         console.log('No Instance ID token available. Request permission to generate one.');
//         // Show permission UI.
//         updateUIForPushPermissionRequired();
//         setTokenSentToServer(false);
//       }
//     })
//     .catch(function(err) {
//       console.log('An error occurred while retrieving token. ', err);
//       showToken('Error retrieving Instance ID token. ', err);
//       setTokenSentToServer(false);
//     });
//   // [END get_token]
// }

// function showToken(currentToken) {
//   // Show token in console and UI.
//   const tokenElement = document.querySelector('#token');
//   tokenElement.textContent = currentToken;
// }

// Send the Instance ID token your application server, so that it can:
// - send messages back to this app
// - subscribe/unsubscribe the token from topics
// function sendTokenToServer(currentToken) {
//   if (!isTokenSentToServer()) {
//     console.log('Sending token to server...');
//     // TODO(developer): Send the current token to your server.
//     setTokenSentToServer(true);
//   } else {
//     console.log("Token already sent to server so won't send it again unless it changes ");
//   }
// }

// function isTokenSentToServer() {
//   return window.localStorage.getItem('sentToServer') === '1';
// }
// function setTokenSentToServer(sent) {
//   window.localStorage.setItem('sentToServer', sent ? '1' : '0');
// }
// function showHideDiv(divId, show) {
//   const div = document.querySelector(`#${divId}`);
//   if (show) {
//     div.style = 'display: visible';
//   } else {
//     div.style = 'display: none';
//   }
// }
// function requestPermission() {
//   console.log('Requesting permission...');
//   // [START request_permission]
//   messaging
//     .requestPermission()
//     .then(function() {
//       console.log('Notification permission granted.');
//       // TODO(developer): Retrieve an Instance ID token for use with FCM.
//       // [START_EXCLUDE]
//       // In many cases once an app has been granted notification permission, it
//       // should update its UI reflecting this.
//       resetUI();
//       // [END_EXCLUDE]
//     })
//     .catch(function(err) {
//       console.log('Unable to get permission to notify.', err);
//     });
//   // [END request_permission]
// }

// function deleteToken() {
//   // Delete Instance ID token.
//   // [START delete_token]
//   messaging
//     .getToken()
//     .then(function(currentToken) {
//       messaging
//         .deleteToken(currentToken)
//         .then(function() {
//           console.log('Token deleted.');
//           setTokenSentToServer(false);
//           // [START_EXCLUDE]
//           // Once token is deleted update UI.
//           resetUI();
//           // [END_EXCLUDE]
//         })
//         .catch(function(err) {
//           console.log('Unable to delete token. ', err);
//         });
//       // [END delete_token]
//     })
//     .catch(function(err) {
//       console.log('Error retrieving Instance ID token. ', err);
//       showToken('Error retrieving Instance ID token. ', err);
//     });
// }

//  Add a message to the messages element.
// function appendMessage(payload) {
//   const messagesElement = document.querySelector('#messages');
//   const dataHeaderELement = document.createElement('h5');
//   const dataElement = document.createElement('pre');
//   dataElement.style = 'overflow-x:hidden;';
//   dataHeaderELement.textContent = 'Received message:';
//   dataElement.textContent = JSON.stringify(payload, null, 2);
//   messagesElement.appendChild(dataHeaderELement);
//   messagesElement.appendChild(dataElement);
// }

// Clear the messages element of all children.
// function clearMessages() {
//   const messagesElement = document.querySelector('#messages');
//   while (messagesElement.hasChildNodes()) {
//     messagesElement.removeChild(messagesElement.lastChild);
//   }
// }

// function updateUIForPushEnabled(currentToken) {
//   // showHideDiv(tokenDivId, true);
//   // showHideDiv(permissionDivId, false);
//   showToken(currentToken);
// }

// function updateUIForPushPermissionRequired() {
//   // showHideDiv(tokenDivId, false);
//   // showHideDiv(permissionDivId, true);
// }

/* // importing it
import 'firebase/messaging'
let messaging = null
if (firebase.messaging.isSupported()) {
   messaging = firebase.messaging()
   messaging.usePublicVapidKey("{yourkey")
}
export { messaging }

// actually using it
import { messaging } from 'firebase.js'
FCM() {
   if (!messaging) return
   messaging.requestPermission()
   .then(() => {
      ...
   }
} */
