import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import apiClient from 'config/apiClient';

// @material-ui/core
import withStyles from '@material-ui/core/styles/withStyles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

// Assets
import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import { actionButton, blankCardHeader, customSelectStyles } from 'assets/jss/material-dashboard-pro-react';

// Components
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from 'components/CustomButtons/Button';
// import { isCrue, isIps } from 'utils/authFuntions';

import { urlApi } from '../../config/app';

const telecareStyles = {
  ...dashboardStyle,
  actionButton,
  blankCardHeader,
  selectStyles: {
    ...customSelectStyles.formControl,
    maxWidth: '300px'
  },
};

const Telecare = props => {
  const { classes } = props;
  const [ambulances, setAmbulances] = useState([]);
  const [selectedAmbulance, setSelectedAmbulance] = useState();

  const startTelecare = () => {
    const ambulance = ambulances.find(amb => amb.id == selectedAmbulance);
    window.open(ambulance.hostRoomUrl);
  };

  useEffect(() => {
    apiClient.SEM.get(`${urlApi}/ambulance/legacyTelecare`)
      .then(({ data }) => {
        setAmbulances(data.data);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  const hasRoom = useCallback(
    () => {
      const ambulance = ambulances.find(amb => amb.id == selectedAmbulance) || {};
      return ambulance.guestRoomUrl;
    },
    [selectedAmbulance]
  );
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="blank">
            <h3>Teleorientación</h3>
          </CardHeader>
          <CardBody>
            <GridItem container>
              <GridItem xs={12} sm={4}>
                <InputLabel htmlFor="simple-select" error={!selectedAmbulance}>
                  Ambulancia *
                </InputLabel>
                <FormControl fullWidth style={{ marginTop: '10px' }} className={classes.selectStyles}>
                  <Select
                    disableUnderline
                    value={selectedAmbulance || ''}
                    onChange={e => {
                      setSelectedAmbulance(e.target.value);
                    }}
                    fullWidth
                    inputProps={{
                      name: 'ambulance',
                      id: 'ambulance'
                    }}
                  >
                    {ambulances.map(amb => (
                      <MenuItem key={amb.id} value={amb.id}>
                        {amb.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {selectedAmbulance && !hasRoom() && (
                    <FormHelperText error>La ambulancia no cuenta con una sala definida</FormHelperText>
                  )}
                </FormControl>
              </GridItem>
            </GridItem>
            <GridItem container justify="center">
              <Button
                onClick={startTelecare}
                style={{ marginTop: '20px' }}
                className={classes.actionButton}
                disabled={!selectedAmbulance || !hasRoom()}
              >
                Iniciar
              </Button>
            </GridItem>
          </CardBody>
        </Card>
        <GridContainer />
      </GridItem>
    </GridContainer>
  );
};

Telecare.propTypes = {
  classes: PropTypes.object.isRequired
};

const TelecareComponent = withStyles(telecareStyles)(Telecare);

export default TelecareComponent;
