import React from 'react';
import PropTypes from 'prop-types';
import { InputAdornment, makeStyles, TextField } from '@material-ui/core';
import SearchOutlined from '@material-ui/icons/SearchOutlined';

const useStyles = makeStyles({
  input: {
    flexGrow: 1,
    flexShrink: 1,
    minWidth: '250px',
    maxWidth: '250px',
    '& .MuiInputBase-root': {
      padding: 0,
      '& .MuiInputBase-input': {
        padding: '7px'
      },
    },
    '& .MuiOutlinedInput-notchedOutline:hover': {
      border: 'solid 1px red'
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      border: 'solid 1px #aaa'
    },
    '& .MuiOutlinedInput-root .Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: 'solid 1px #a9a9a9'
    },
    '@media (max-width: 550px)': {
      // minWidth: '60%',
    }
  },
  adornment: {
    '& svg': {
      transform: 'scale(1.4)',
      fill: '#ccc'
    }
  }
});

const SearchInputComponent = ({ value, onValueChange }) => {
  const classes = useStyles();
  const onChange = e => {
    onValueChange(e.target.value);
  };

  return (
    <TextField
      className={classes.input}
      outlined
      defaultValue={value}
      onChange={onChange}
      placeholder="Buscar"
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment className={classes.adornment} position="start">
            <SearchOutlined />
          </InputAdornment>
        )
      }}
    />
  );
};

SearchInputComponent.propTypes = {
  value: PropTypes.string,
  onValueChange: PropTypes.func
};

export default SearchInputComponent;
