import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import apiClient from 'config/apiClient';
import { useAlert } from 'react-alert';
import CustomInput from 'components/CustomInput/CustomInput';

// @material-ui/core
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

// Components
import Button from 'components/CustomButtons/Button';
import GridItem from 'components/Grid/GridItem';

import { urlApi } from '../../config/app';

const FileDownload = require('js-file-download');

const Annex5Report = ({ valid, validFinalDate, classes }) => {
  const [inputs, setInputs] = useState({});
  const alert = useAlert();
  const handleChange = useCallback((name, value) => {
    setInputs({ ...inputs, [name]: value });
  }, []);
  const downloadAnnex5 = () => {
    const { initialDate, endDate, referralId } = inputs;
    const params = {
      initialDate: initialDate ? initialDate.format('X') : undefined,
      endDate: endDate ? endDate.format('X') : undefined,
      referralId
    };
    handleChange('loading', true);
    apiClient.SEM.get(`${urlApi}/reports/transports`, {
      responseType: 'blob',
      params
    })
      .then(async response => {
        const regexp = new RegExp('zip');
        if (regexp.test(response.headers['content-type'])) {
          FileDownload(response.data, `reportes_anexo5.zip`);
        } else {
          const error = JSON.parse(await response.data.text());
          const errorMsg =
            error.message === 'REFERRAL_TRANSPORT_NOT_FOUND'
              ? 'No hay información del anexo 5  con los parámetros ingresados'
              : error.message;
          alert.show(`${errorMsg}`, {
            type: 'error',
            timeout: 5000
          });
        }
      })
      .catch(error => {
        alert.show(`Se ha producido un error al descargar el archivo.`, {
          type: 'error',
          timeout: 5000
        });
        console.error(`Se ha producido un error al descargar el archivo: ${error}`);
      })
      .finally(() => handleChange('loading', false));
  };

  return (
    <div>
      <div style={{ display: 'flex', marginTop: '15px' }}>
        <GridItem xs={12} sm={4}>
          <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
            Fecha inicial
          </InputLabel>
          <FormControl fullWidth>
            <Datetime
              viewMode="years"
              dateFormat="YYYY-MMM-DD"
              timeFormat={false}
              value={inputs.initialDate}
              isValidDate={idate => valid(idate)}
              closeOnSelect
              inputProps={{
                placeholder: 'Fecha inicial',
                required: true,
                readOnly: true
              }}
              onChange={date => setInputs({ ...inputs, initialDate: date })}
            />
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={4}>
          <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
            Fecha final
          </InputLabel>
          <FormControl fullWidth disabled={!inputs.initialDate}>
            <Datetime
              viewMode="years"
              dateFormat="YYYY-MMM-DD"
              timeFormat={false}
              value={inputs.endDate}
              isValidDate={date => validFinalDate(date, inputs.initialDate)}
              closeOnSelect
              inputProps={{
                placeholder: 'Fecha Final',
                required: true,
                readOnly: true,
                disabled: !inputs.initialDate
              }}
              onChange={date => setInputs({ ...inputs, endDate: date })}
            />
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={4}>
          <CustomInput
            labelText="Buscar por ID deltraslado"
            formControlProps={{ fullWidth: true }}
            id="secadConsecutiveNumber"
            error={false}
            inputProps={{
              onChange: event => setInputs({ ...inputs, referralId: event.target.value }),
              autoComplete: 'off',
              name: 'referralId',
              value: inputs.referralId || '',
              type: 'number'
            }}
          />
        </GridItem>
      </div>
      <p>
        <i>Puedes descargar los reportes del anexo 5 por fechas o por el número del traslado.</i>
      </p>
      <GridItem container justify="center">
        <Button
          onClick={downloadAnnex5}
          className={classes.actionButton}
          style={{ marginTop: '20px' }}
          color="info"
          disabled={inputs.loading}
        >
          {inputs.loading ? 'Descargando...' : 'Descargar'}
        </Button>
      </GridItem>
    </div>
  );
};

Annex5Report.propTypes = {
  valid: PropTypes.func.isRequired,
  validFinalDate: PropTypes.func.isRequired,
  classes: PropTypes.object
};

export default Annex5Report;

// const OccupationReportComponent = withStyles(dashboardStyle)(Annex5Report);

// export default OccupationReportComponent;
